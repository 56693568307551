.profile__header {
  &.guest__profile {
    & .user-dropdown__toggle-name {
      color: #3b3b3b;
      font-size: 13px;
      line-height: 17px;
    }
  }
}

.complex__content {
  width: 100%;
  height: 100%;

  width: calc(100% - 50px);
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  height: auto;
  margin-top: 85px;
  min-height: calc(100vh - 110px);
  padding: 25px 30px;

  & .help__block {
    &>h2 {
      margin-top: 0px;
      margin-bottom: 20px;
      font-size: 25px;
      line-height: 34px;
      font-weight: bold;
      color: #0e7d7d;
    }

    & .help__links {
      &>.elem__help {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0px;
        }

        &>a {
          display: flex;

          align-items: flex-start;
          width: 100%;
          max-width: 540px;
          background: #ffffff;
          border: 1px solid #e3e3e3;
          border-radius: 10px;
          transition: 0.4s ease all;
          padding: 15px;
          padding-right: 75px;
          justify-content: flex-start;
          position: relative;

          &:hover {
            border-color: #0e7d7d;

            & .help__svg {
              background-color: #0e7d7d;

              & svg {
                & path {
                  fill: #fff;
                }
              }
            }

            & .help__arrow {
              &>img {
                opacity: 1;
              }
            }
          }
        }

        & .help__arrow {
          min-width: 46px;
          margin-left: 45px;
          position: absolute;
          right: 15px;
          top: 32px;

          &>img {
            opacity: 0.15;
            transition: 0.4s ease all;
          }
        }

        & .help__svg {
          min-width: 50px;
          max-width: 50px;
          min-height: 50px;
          margin-right: 10px;
          max-height: 50px;
          background: #e7f2f2;
          border-radius: 100px;
          display: inline-flex;
          transition: 0.4s ease all;
          align-items: center;

          & svg {
            & path {
              transition: 0.4s ease all;
            }
          }

          justify-content: center;
        }

        & .help__info {
          &>h6 {
            margin-top: 0px;
            margin-bottom: 3px;
            font-size: 16px;
            line-height: 21px;
            font-weight: bold;
            color: #0e7d7d;
          }

          &>p {
            margin: 0px;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            color: #3b3b3b;
          }
        }
      }
    }
  }
}

.checkout__long {
  width: calc(100% - 60px);
  margin-top: 100px;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.checkout__progress--section {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .element__progress {
    display: flex;
    align-items: center;
    width: 100%;
    height: 45px;
    position: relative;
    justify-content: center;
    position: relative;

    &.done__progress {
      &>p {
        color: #0e7d7d;
      }

      &>span {
        background-color: #0e7d7d;
      }
    }

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

    &.active__progress {
      background: #e7f2f2;

      &>p {
        color: #0e7d7d;
      }

      & span {
        border-color: #0e7d7d;
        background-color: #fff;
        color: #0e7d7d;
      }
    }

    & span {
      min-width: 30px;
      max-width: 30px;
      min-height: 30px;
      max-height: 30px;
      border-radius: 250px;
      background: #f5f5f5;
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
      color: #2d2d2d;
      display: inline-flex;
      align-items: center;
      border: 2px solid #f5f5f5;
      justify-content: center;
      position: absolute;
      left: 18px;
    }

    clip-path: polygon(0 0,
      calc(100% - 15px) 0%,
      100% 50%,
      calc(100% - 15px) 100%,
      0 99%,
      15px 50%);
    background-color: #fff;

    &>p {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      margin: 0px;
      color: #2d2d2d;
    }
  }
}

.field__custom {
  width: 100%;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0px;
  }

  &>input {
    width: 100%;
    height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    background: #f5f5f5;
    border-radius: 3px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    text-decoration: none;
  }

  &>textarea {
    width: 100%;
    height: 70px;
    padding-left: 15px;
    padding-right: 15px;
    background: #f5f5f5;
    border-radius: 3px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    text-decoration: none;
    resize: none;
    padding-top: 10px;
  }
}

.checkout__long {
  padding-bottom: 25px;

  & .content__guest {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 20px;

    &>.guest__content--main {
      & .lang__pair {
        &>.head__pair {
          margin-bottom: 30px;

          &>h2 {
            margin: 0px;
            font-size: 18px;
            line-height: 24px;
            font-weight: bold;
            color: #2d2d2d;
          }
        }
      }

      & .signed__form {
        & .element__signed--controls {
          max-width: 320px;
          margin-bottom: 30px;

          & .select__dropdown {
            margin-top: 0px;
          }

          &:last-child {
            margin-bottom: 0px;
          }
        }

        & .field__checkbox {
          & .checkbox__name {
            font-weight: 400;
          }
        }

        & form {
          & .signed__head {
            margin-bottom: 30px;

            &>p {
              margin: 0px;
              font-size: 14px;
              line-height: 20px;
              font-weight: 400;
              color: #2d2d2d;

              & a {
                font-weight: 600;
                color: #0e7d7d;
                text-decoration: underline;
              }
            }

            &>h2 {
              margin-top: 0px;
              margin-bottom: 10px;
              color: #2d2d2d;
              font-size: 18px;
              line-height: 24px;
              font-weight: bold;

              & a {
                color: #0e7d7d;
              }
            }
          }
        }
      }

      & .sign__form {
        max-width: 320px;

        & .forgot__pass {
          margin-top: 20px;
          display: flex;
          justify-content: center;
          align-items: center;

          &>a {
            min-height: 40px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            text-decoration: none;
            color: #0e7d7d;
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            transition: 0.4s ease all;

            &:hover {
              opacity: 0.7;
            }
          }
        }

        & .button__guest {
          width: 100%;
          max-width: 320px;
          margin-bottom: 10px;

          &>a {
            display: inline-flex;
            width: 100%;
            min-height: 40px;
            border-radius: 3px;
            background: #ebf1f1;
            border: 1px solid #0e7d7d;
            border-radius: 3px;
            justify-content: center;
            align-items: center;
            color: #0e7d7d;
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            transition: 0.4s ease all;

            &:hover {
              color: #fff;
              background-color: #094f4f;
            }

            &:active {
              background-color: #094f4f;
            }

            &:focus {
              color: #ffffff;
              background-color: #0a5858;
              box-shadow: 0 0 0 0.2rem rgba(14, 125, 125, 0.5);
            }
          }
        }

        & .field__submit {
          width: 100%;
          max-width: 320px;
          margin-bottom: 10px;

          &>input[type='submit'] {
            width: 100%;
            height: 40px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background: #0e7d7d;
            border-radius: 3px;
            font-size: 14px;
            line-height: 20px;
            font-weight: bold;
            color: #fff;
            text-decoration: none;
            cursor: pointer;
            transition: 0.4s ease all;

            &:hover {
              background-color: #0a5858;
            }

            &:active {
              background-color: #094f4f;
            }

            &:focus {
              color: #ffffff;
              background-color: #0a5858;
              box-shadow: 0 0 0 0.2rem rgba(14, 125, 125, 0.5);
            }
          }
        }

        & .sign__error {
          margin-bottom: 15px;

          &>p {
            font-size: 14px;
            color: #b13634;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            &>span {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              margin-right: 10px;
            }
          }
        }

        & .sign__head {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 20px;

          &>h2 {
            margin: 0px;
            margin-right: 12px;
            font-size: 18px;
            line-height: 24px;
            color: #2d2d2d;
            font-weight: bold;
          }

          &>p {
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            color: #2d2d2d;
          }
        }
      }

      width: 100%;
      padding: 30px;
      background: #ffffff;
      box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
      border-radius: 5px;

      & .field__checkbox {
        margin-top: 15px;
      }

      min-height: 520px;

      & .guest__checkout {
        & .guest__business {
          max-width: 320px;
          width: 100%;
        }

        & .guest__private {
          max-width: 320px;
          width: 100%;

          & .field__checkbox {
            margin-top: 15px;
          }
        }

        & .guest__switcher {
          padding: 3px;
          width: 100%;
          max-width: 320px;
          background: #f5f5f5;
          border-radius: 7px;
          margin-bottom: 15px;

          & ul {
            padding: 0px;
            list-style-type: none;
            margin: 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            &>li {
              display: flex;
              width: 100%;
              margin-right: 5px;
              justify-content: center;
              align-items: center;

              &.current__switch {
                &>a {
                  background-color: #0e7d7d;
                  color: #fff;
                }
              }

              &>a {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                min-height: 30px;
                border-radius: 5px;
                width: 100%;
                transition: 0.4s ease all;

                &:hover {
                  background-color: #0e7d7d;
                  color: #fff;
                }
              }

              &:last-child {
                margin-right: 0px;
              }
            }
          }
        }

        & .guest__head {
          margin-bottom: 35px;

          &>p {
            margin: 0px;
            color: #000;
            font-size: 14px;
            font-weight: 400;

            & a {
              font-weight: 600;
              text-decoration: underline;
              color: #0e7d7d;
            }
          }

          &>h6 {
            margin: 0px;
            margin-bottom: 3px;
            color: #2d2d2d;
            font-size: 18px;
            line-height: 24px;
            font-weight: bold;
          }
        }
      }
    }

    &>.guest__content--side {
      min-width: 330px;
      margin-left: 20px;
      max-width: 330px;

      & .guest__security {
        margin-top: 20px;
        background: #e7f2f2;
        border-radius: 5px;
        padding: 15px 25px;

        & .security__image {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          &>img {
            max-width: 100px;
          }
        }

        & .security__info {
          margin-bottom: 10px;

          &>p {
            margin: 0px;
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
            color: #2d2d2d;
          }
        }

        &>.guest__security--head {
          &>h2 {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 0px;
            font-size: 14px;
            line-height: 24px;
            color: #0e7d7d;
            font-weight: bold;

            & span {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              margin-right: 15px;
            }
          }

          margin-bottom: 8px;
        }
      }

      & .guest__summary {
        width: 100%;

        & .summary__total {
          &>.summary__total--button {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            &>a {
              display: inline-flex;
              width: 100%;
              align-items: center;
              justify-content: center;
              min-height: 40px;
              background: #0e7d7d;
              box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
              border-radius: 3px;
              font-size: 14px;
              line-height: 20px;
              font-weight: 600;
              color: #fff;
              text-decoration: none;
              transition: 0.4s ease all;

              &:active {
                background-color: #094f4f;
              }

              &:focus {
                color: #ffffff;
                background-color: #0a5858;

                box-shadow: 0 0 0 0.2rem rgba(14, 125, 125, 0.5);
              }

              &:hover {
                background-color: #0a5858;
              }

              & span {
                color: rgba(255, 255, 255, 0.5);
                margin-right: 4px;
              }
            }
          }

          &>.summary__total--info {
            display: flex;
            align-items: flex-start;
            margin-bottom: 20px;

            & .summary__total--price {
              text-align: right;

              &>span {
                display: inline-flex;
                margin-bottom: 5px;
                font-size: 16px;
                line-height: 20px;
                font-weight: bold;
                color: rgba(14, 125, 125, 1);
              }

              &>p {
                margin: 0px;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                color: rgba(45, 45, 45, 0.5);

                & span {
                  color: #2d2d2d;
                }
              }
            }

            &>span {
              margin-right: 15px;
              white-space: nowrap;
              font-size: 16px;
              line-height: 20px;
              font-weight: bold;
              color: #2d2d2d;
            }

            &>p {
              margin: 0px;
              font-size: 14px;
              line-height: 20px;
              color: #9e9e9e;
              font-weight: 600;
            }

            justify-content: space-between;
          }
        }

        & .summary__total {
          & .summary__total--button {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &>.summary__back--button {
              margin-top: 10px;
              width: 100%;

              &>a.summary__back {
                width: 100%;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                min-height: 40px;
                background: #ebf1f1;
                border-radius: 3px;
                font-weight: 600;
                transition: 0.4s ease all;

                &:hover {
                  opacity: 0.7;
                }

                color: #0e7d7d;

                & span {
                  color: #7db7b7;
                }
              }
            }
          }
        }

        & .summary__project {
          & .summary__element {
            position: relative;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: 15px;
            padding-bottom: 0px;

            &:last-child {
              border-bottom: 1px solid #e7e7e7;
              padding-bottom: 15px;
            }

            &>.summary__right {
              display: flex;
              align-items: center;
              justify-content: flex-end;

              &>p {
                white-space: nowrap;
                margin: 0px;
                margin-right: 10px;
                font-size: 14px;
                line-height: 20px;
                color: #2d2d2d;
                font-weight: bold;
              }

              &>.summary__remove {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                min-width: 14px;
                max-width: 14px;
                min-height: 14px;
                max-height: 14px;

                &>a {
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }

            &>.summary__left {
              margin-right: 10px;

              &>h2 {
                margin-top: 0px;
                margin-bottom: 5px;
                font-size: 14px;
                line-height: 20px;
                color: #2d2d2d;
              }

              &>ul {
                padding: 0px;
                list-style-type: none;
                margin: 0px;

                &>li {
                  color: #2d2d2d;
                  font-size: 14px;
                  line-height: 20px;
                  font-weight: 400;
                  margin-bottom: 5px;

                  &:last-child {
                    margin-bottom: 0px;
                  }
                }
              }
            }
          }

          & .summary__empty {
            border-bottom: 1px solid #e7e7e7;
            margin-bottom: 15px;
            min-height: 80px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            width: 100%;

            &>p {
              margin: 0px;
              font-size: 14px;
              line-height: 20px;
              color: #2d2d2d;
              font-weight: bold;
            }
          }
        }

        & .summary__head {
          margin-bottom: 15px;
          border-bottom: 1px solid #e7e7e7;
          padding-bottom: 15px;

          &>h2 {
            text-align: center;
            margin: 0px;
            font-size: 16px;
            line-height: 24px;
            color: #0e7d7d;
            font-weight: bold;
          }
        }
      }

      &>.guest__summary {
        width: 100%;
        padding: 15px 20px;
        padding-bottom: 30px;
        background: #ffffff;
        box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
      }
    }
  }
}

.pair__picker {
  margin-bottom: 20px;

  & .pair__container {
    width: 100%;

    & .upload__block.upload-content__drag-drop__old {
      max-width: 100%;
    }

    & .upload__block.upload-content__files {
      max-width: 100%;
      padding-top: 0px;
    }

    &>.head__pair {
      padding: 20px;
      border-bottom: 1px solid #9d9d9d;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &>.pair__remove {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &>a {
          display: inline-flex;
          align-items: center;
          justify-content: center;

          &>img {
            min-width: 18px;
          }
        }
      }

      &>.pair__double {
        display: flex;
        align-items: center;
        width: 100%;

        & .pair__elem {
          width: 100%;
          max-width: 215px;

          & .group__tag--checkbox {
            margin-bottom: 0px;

            &>.group__target--container {
              position: relative;

              &:before {
                content: '';
                position: absolute;
                right: 10px;
                width: 12px;
                height: 15px;
                background: url('../images/servicearrow.svg');
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                top: 50%;
                transform: translate(0%, -50%);
              }

              border: 0px;
              background: none;
              background-color: #fff;
            }
          }

          & .select__dropdown {
            margin-top: 0px;
          }

          & .select__label {
            background-color: #fff;

            &:before {
              background-color: #fff;
            }
          }
        }

        justify-content: flex-start;

        &>.pair__spacer {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin: 0px 15px;

          &>img {
            min-width: 14px;
          }
        }
      }
    }

    background: #f7f7f7;
    border-radius: 10px;
  }
}

.lang__pair {
  & .one__more--package {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &>a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 10px 20px;
      background: #ffffff;
      border: 1px solid #0e7d7d;
      box-sizing: border-box;
      box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
      border-radius: 3px;
      font-size: 14px;
      line-height: 21px;
      color: #0e7d7d;
      font-weight: bold;
      text-decoration: none;
      transition: 0.4s ease all;

      &:hover {
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      }

      &:active,
      &:focus {
        border-color: rgba(14, 125, 125, 0.3);
      }

      &>span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
      }

      &>img {
        margin-right: 10px;
        min-width: 14px;
      }
    }
  }
}

.info__tooltip--block {
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px 10px 10px 0px;
  padding: 12px;
  min-width: 190px;
  max-width: 190px;
  background-color: #fff;
  position: absolute;
  display: none;
  left: 50%;
  transform: translate(-50%, -100%);
  z-index: 5;
  margin-top: -90px;

  &>p {
    font-size: 13px;
    color: #000;
    font-weight: 400;
  }
}

.thank__guest {
  width: 100%;
  background: #ffffff;
  min-height: calc(100vh - 110px);
  box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding-top: 60px;
  padding-bottom: 85px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  & .thank__go--order {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &>p {
      margin: 0px;
      color: #2d2d2d;
      font-size: 14px;
      font-weight: 400;

      & a {
        font-weight: 600;
        text-decoration: underline;
        color: #0e7d7d;
      }

      line-height: 21px;
    }

    &>a {
      width: 100%;
      max-width: 290px;
      min-height: 40px;
      background: #0e7d7d;
      box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05), 0px 4px 5px rgba(0, 0, 0, 0.05);
      border-radius: 3px;
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      font-weight: bold;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  & .thank__controls {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 560px;
    margin-left: auto;
    margin-right: auto;

    &>a {
      display: inline-flex;
      align-items: center;
      width: 100%;
      font-size: 14px;
      line-height: 21px;
      color: rgba(14, 125, 125, 1);
      font-weight: 600;
      text-decoration: none;
      transition: 0.4s ease all;
      min-height: 40px;
      border: 1px solid #0e7d7d;
      border-radius: 4px;

      &:focus {}

      &:hover {
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      }

      &:nth-child(1) {
        margin-right: 15px;

        &:last-child {
          margin-right: 0px;
        }
      }

      justify-content: center;

      &>span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 13px;
      }
    }
  }

  &>.thank__step--one {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & .thank__faq {
    max-width: 560px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 20px;
    width: 100%;

    &>h6 {
      text-align: center;
      margin-top: 0px;
      margin-bottom: 10px;
      font-size: 24px;
      line-height: 32px;
      color: rgba(14, 125, 125, 1);
      font-weight: 600;
    }

    &>.thank__faq--elem {
      margin-bottom: 5px;
      width: 100%;

      &.faq__active {
        & .thank__plus {
          opacity: 0 !important;
        }
      }

      &>.thank__faq--content {
        padding: 25px 30px;
        background: #f3f3f3;
        text-align: left;
        padding-top: 10px;
        display: none;
        width: 100%;

        &>p {
          margin: 0px;
          color: #2d2d2d;
          font-size: 16px;
          line-height: 24px;
        }
      }

      & .thank__faq--title {
        display: flex;
        background: #f3f3f3;
        cursor: pointer;
        align-items: center;
        padding-top: 15px;
        padding-bottom: 15px;
        width: 100%;
        padding-left: 30px;
        padding-right: 15px;

        &>.thank__state {
          margin-left: 12px;
          min-width: 34px;
          max-width: 34px;
          min-height: 34px;
          border-radius: 50px;
          max-height: 34px;
          background: #ffffff;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.03);
          position: relative;

          &>.thank__plus {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            min-height: 16px;
            min-width: 2px;
            background-color: #589999;
            transition: 0.3s ease all;
          }

          &>.thank__minus {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            min-width: 16px;
            min-height: 2px;
            background-color: #589999;
            transition: 0.3s ease all;
          }
        }

        &>p {
          margin: 0px;
          text-transform: uppercase;
          color: rgba(29, 28, 25, 1);
          text-align: left;
          font-size: 14px;
          line-height: 19px;
          font-weight: 600;
        }

        justify-content: space-between;
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }

    & .thank__faq--head {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &>p {
        margin: 0px;
        color: rgba(45, 45, 45, 1);
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }

  & .thank__questions {
    text-align: center;

    &>p {
      margin: 0px;
      font-size: 14px;
      line-height: 21px;
      color: #2d2d2d;
      font-weight: 400;

      & a {
        font-weight: 600;
        text-decoration: underline;
        color: #0e7d7d;
      }
    }
  }

  & .thank__final--download {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 330px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 35px;

    &>a {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      width: 100%;
      font-size: 14px;
      line-height: 21px;
      color: #0e7d7d;
      font-weight: 600;
      text-decoration: none;
      -webkit-transition: 0.4s ease all;
      transition: 0.4s ease all;
      min-height: 40px;
      border: 1px solid #0e7d7d;
      border-radius: 4px;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      &:hover {
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      }

      & span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
      }
    }
  }

  & .thank__review {
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center;

    &>p {
      margin-top: 0px;
      margin-bottom: 35px;

      font-size: 18px;
      line-height: 24px;
      color: rgba(45, 45, 45, 1);
      font-weight: 400;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  & .thank__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &>p {
      margin: 0px;
      padding: 15px 20px;
      background: #e7f2f2;
      border-radius: 5px;
      font-size: 18px;
      line-height: 24px;
      color: rgba(14, 125, 125, 1);
      text-align: center;
      font-weight: 600;
    }

    &>h2 {
      text-align: center;
      margin-top: 0px;
      margin-bottom: 15px;
      font-size: 26px;
      line-height: 35px;
      color: rgba(14, 125, 125, 1);
      font-style: bold;
    }
  }

  & .thank__image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
}

.guest__content--main {
  & .payment__method {
    width: 100%;
    max-width: 420px;

    & .payment__method--grid {
      width: 100%;
      background: #f7f8fa;
      border: 1px solid #e3e3e3;
      border-radius: 4px;
      overflow: hidden;

      &>.elem__payment--method {
        padding: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e3e3e3;

        & .radio__box {
          margin-right: 0px;
        }

        &>p {
          display: inline-flex;
          align-items: center;
          font-size: 14px;
          line-height: 20px;
          color: rgba(120, 120, 120, 1);
          font-weight: 600;
          margin: 0px;
          justify-content: flex-start;
          margin-right: 10px;

          &>span {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            min-width: 52px;
            min-height: 35px;
            margin-right: 10px;
          }
        }
      }
    }

    &>h2 {
      margin-top: 0px;
      margin-bottom: 30px;
      font-size: 18px;
      line-height: 24px;
      color: rgba(45, 45, 45, 1);
      font-weight: bold;
    }
  }
}

.guest__payment--method {
  &>h2 {
    margin-top: 0px;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 24px;
    color: rgba(45, 45, 45, 1);
    font-weight: bold;
  }

  &>.timeline__block {
    margin-top: 30px;

    &>.choose__timeline--grid {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &>.elem__choose--timeline {
        &.active__choose--timeline {
          background-color: rgba(14, 125, 125, 1);

          & .info__tooltip {
            & svg {
              & circle {
                fill: rgba(0, 0, 0, 0.25);
              }
            }
          }

          &>p {
            & path {
              fill: #fff;
            }

            color: #fff;

            &.deadline {
              color: #fff;
            }
          }

          & .checkbox {
            & .checkbox__checkmark {
              border-color: #fff;
              background-color: #fff;

              &:before {
                background-image: url('../images/checkgreen.svg');
                background-size: 10px 8px;
                background-repeat: no-repeat;
                background-position: center;
              }
            }
          }
        }

        &>.checkbox {
          margin-right: 10px;
        }

        padding: 5px 15px;
        cursor: pointer;
        border: 1px solid #0e7d7d;
        box-sizing: border-box;
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
        border-radius: 3px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        min-height: 40px;
        justify-content: flex-start;

        & .info__tooltip {
          & circle {
            transition: 0.3s ease all;
          }
        }

        & p {
          &.deadline {
            font-weight: 400;
            color: rgba(59, 59, 59, 1);
          }

          color: rgba(14, 125, 125, 1);
          font-size: 14px;
          line-height: 14px;
          font-weight: 600;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          &>span {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
          }
        }

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    &>h6 {
      margin: 0px;
      margin-bottom: 10px;
      font-size: 18px;
      line-height: 24px;
      color: rgba(59, 59, 59, 1);
      font-weight: 600;
    }
  }

  &>.chosen__element {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 605px;
    padding: 15px 20px;
    background: #f3f9f9;
    margin-bottom: 30px;

    &>.chosen__info--help {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &>ul {
        min-width: 165px;

        &>li {
          margin-bottom: 10px;

          &>p {
            margin: 0px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 14px;
            line-height: 18px;
            color: rgba(59, 59, 59, 1);
            font-weight: 600;

            &>span {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              margin-right: 10px;

              &>img {
                min-width: 12px;
              }
            }
          }

          &:last-child {
            margin-bottom: 0px;
          }
        }
      }

      &>h2 {
        margin: 0px;
        font-size: 20px;
        line-height: 27px;
        font-weight: bold;
        white-space: nowrap;
        margin-left: 15px;
      }
    }

    &>.chosen__info--left {
      min-width: 200px;

      &>h2 {
        margin-top: 0px;
        margin-bottom: 10px;
        font-size: 20px;
        line-height: 27px;
        color: rgba(14, 125, 125, 1);
        font-weight: bold;
      }

      &>p {
        margin: 0px;
        font-size: 14px;
        line-height: 20px;
        color: rgba(59, 59, 59, 1);
        font-weight: 400;
      }
    }

    &>.spacer {
      height: 55px;
      margin: 0px 15px;
      min-width: 1px;
      display: inline-flex;
      background-color: #97c7c7;
    }

    &>.chosen__info--left {
      max-width: 200px;
    }
  }
}

.brief__wrapper {
  & .upload-content__drag-drop.upload-content__drag-drop__old.upload__block {
    position: relative;

    & .supported__files {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 12px;
      bottom: 12px;

      &>p {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 12px;
        line-height: 18px;
        font-weight: 600;
        color: rgba(157, 157, 157, 1);

        & span {
          margin-top: 0px;
          margin-bottom: 0px;
          margin-left: 10px;
        }
      }
    }
  }

  & .form-group {
    & textarea {
      width: 100%;
      height: 80px;
      background: #f2f2f2;
      border-radius: 3px;
      border-color: #f2f2f2;
    }

    & .form-group__header {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &>div {
        margin-left: 7px;
      }

      &>p {
        margin: 0px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        color: #2d2d2d;

        & span {
          opacity: 0.4;
        }
      }
    }
  }

  & .improve__field {
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 500px;

    &>h6 {
      margin-top: 0px;
      margin-bottom: 10px;
      color: #2d2d2d;
      font-size: 14px;
      line-height: 20px;
      font-weight: bold;

      & span {
        opacity: 0.4;
      }
    }
  }

  &>h2 {
    margin-bottom: 20px;
    margin-top: 0px;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    color: rgba(45, 45, 45, 1);
  }
}

.picked__select {
  display: none !important;
}

.select__v--tags {
  & .select__dropdown {
    margin-top: 0px;
  }

  & .v__tags--block {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    & .v__tag--element {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 15px;
      background: #ffffff;
      border: 1px solid #0e7d7d;
      border-radius: 2px;
      box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
      margin-right: 10px;
      margin-bottom: 7px;

      &:last-child {
        margin-right: 0px;
      }

      & p {
        margin: 0px;
        font-size: 12px;
        line-height: 14px;
        font-weight: 600;
        color: rgba(59, 59, 59, 1);
      }

      & a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
      }
    }
  }
}

.additional__guest {
  & .additional__list {
    & .additional__element {
      width: 100%;
      max-width: 570px;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 80px;
      background: #ffffff;
      border: 1px solid #e3e3e3;
      border-radius: 10px;
      position: relative;
      margin-bottom: 10px;

      &.added__additional {
        max-width: 660px;
      }

      &>.additional__right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: 10px;

        &>.price__additional {
          &>p {
            min-height: 35px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 7px 10px;
            font-size: 14px;
            line-height: 20px;
            color: rgba(14, 125, 125, 1);
            font-weight: 600;
            background-color: rgba(231, 242, 242, 1);
            border-radius: 3px;
            text-align: center;
          }
        }

        &>.additional__controls {
          margin-left: 10px;

          &>a {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            min-width: 95px;
            min-height: 35px;
            background: #ffffff;
            border: 1px solid #0e7d7d;
            border-radius: 3px;
            font-size: 14px;
            line-height: 14px;
            font-weight: 600;
            min-height: 35px;
            transition: 0.4s ease all;
            color: rgba(14, 125, 125, 1);

            &:hover {
              box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
            }
          }
        }
      }

      &>.additional__left {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &>.additional__left--text {
          &>h6 {
            margin-top: 0px;
            font-size: 14px;
            line-height: 19px;
            color: #0e7d7d;
            font-weight: bold;
          }

          & p {
            margin: 0px;
            font-size: 12px;
            line-height: 16px;
            color: #000;
            font-weight: 400;
          }
        }

        &>.additional__left--image {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
        }
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  &>h2 {
    margin-top: 0px;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    color: #2d2d2d;

    & span {
      opacity: 0.4;
    }
  }
}

.additional__complex--controls {
  &>.complex__controls--inner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 10px;

    &>.remove__button {
      &>a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 34px;
        max-width: 34px;
        min-height: 34px;
        max-height: 34px;
        border: 1px solid #e3e3e3;
        border-radius: 3px;
        transition: 0.4s ease all;

        &:hover {
          border: 1px solid #9e9e9e;
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
        }
      }
    }

    &>.edit__button {
      margin-right: 10px;

      &>a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 34px;
        max-width: 34px;
        min-height: 34px;
        max-height: 34px;
        border: 1px solid #e3e3e3;
        border-radius: 3px;
        transition: 0.4s ease all;

        &:hover {
          border: 1px solid #9e9e9e;
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
        }
      }
    }

    &>a {
      padding: 7px 15px;
      background: #0e7d7d;
      border: 1px solid #0e7d7d;
      border-radius: 3px;
      min-height: 35px;
      display: flex;
      font-size: 14px;
      line-height: 14px;
      color: rgba(255, 255, 255, 1);
      font-weight: 600;
      align-items: center;
      justify-content: flex-start;
      margin-right: 10px;
      transition: 0.4s ease all;

      &:hover {
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      }

      &>span {
        display: inline-flex;
        margin-right: 10px;
      }
    }
  }
}

.additional__copies--container {
  margin-top: 20px;
  width: 100%;

  & .copies__container--wrapper {
    width: 100%;
    border-top: 1px solid rgba(227, 227, 227, 1);
    border-bottom: 1px solid rgba(227, 227, 227, 1);

    &>.element__copy {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(227, 227, 227, 1);
      padding: 10px 15px;

      & .uncheck__file {
        margin-right: 10px;

        & p {
          display: flex;
          align-items: center;
          margin: 0px;
          font-size: 12px;
          line-height: 18px;
          word-break: break-all;
          font-weight: 400;
          justify-content: flex-start;

          & span {
            margin-right: 10px;
          }
        }
      }

      & .number__copies {
        display: flex;
        align-items: center;
        justify-content: center;

        &>.select {
          & .select__dropdown {
            margin-top: 0px;
          }

          & .select__label {
            &:after {
              right: 7px;
            }

            background-color: #fff;
            border: 1px solid #e3e3e3;
            border-radius: 3px;

            &:before {
              background-color: transparent;
            }
          }

          min-width: 55px;
          max-width: 55px;
        }

        &>p {
          margin: 0px;
          white-space: nowrap;
          font-size: 12px;
          line-height: 18px;
          color: rgba(59, 59, 59, 1);
          margin-right: 12px;
          font-weight: 400;

          & span {
            & img {
              min-width: 24px;
            }
          }
        }
      }

      &:last-child {
        border-bottom: 0px;
      }
    }
  }
}

.element__copy.copy__clone {
  display: none !important;
}

.float__switcher.works__files {
  margin-top: 40px;
}

.works__files--content {
  margin-top: 20px;
  width: 100%;

  &>.files__wrapper {
    position: relative;

    & .supported__files {
      position: absolute;
      right: 15px;
      bottom: 12px;

      &>p {
        margin: 0px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 12px;
        line-height: 18px;
        color: rgba(157, 157, 157, 1);
        font-weight: 600;

        & span {
          margin-top: 0px;
          margin-bottom: 0px;
          margin-left: 10px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.modal__regular.modal__additional--service {
  &>a {
    right: 545px;
  }

  &>.inner__modal--regular {
    max-width: 530px;
  }
}

.additional__float {
  width: 100%;
  z-index: 3;

  & .uncheck__document {
    margin-top: 40px;
    width: 100%;

    & .catcher__uncheck {
      width: 100%;
      overflow-x: auto;
    }

    & .uncheck__list {
      margin-top: 20px;
      border-top: 1px solid #e3e3e3;
      border-bottom: 1px solid #e3e3e3;
      width: 100%;

      & .elem__uncheck {
        display: flex;
        padding: 10px;
        border-bottom: 1px solid #e3e3e3;

        &.unchecked {
          opacity: 0.4;

          & .uncheck__file {
            position: relative;

            &:before {
              content: '';
              position: absolute;
              left: 0px;
              width: 100%;
              height: 1px;
              background-color: #787878;
              top: 50%;
              transform: translate(0%, -50%);
            }
          }
        }

        & .uncheck__check {
          min-width: 30px;
          margin-right: 15px;
        }

        & .uncheck__file {
          width: 100%;

          & p {
            display: flex;
            align-items: center;
            word-break: break-all;
            font-size: 12px;
            line-height: 12px;
            font-weight: 400;
            margin: 0px;
            justify-content: flex-start;

            & span {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              margin-right: 10px;

              &>img {
                min-width: 24px;
                max-width: 24px;
              }
            }
          }
        }

        &:last-child {
          border-bottom: 0px;
        }

        align-items: center;
        justify-content: flex-start;
      }
    }

    &>span {
      font-size: 12px;
      line-height: 16px;
      color: rgba(158, 158, 158, 1);
      font-weight: bold;
    }

    &>p {
      margin: 0px;
      margin-bottom: 0px;

      font-size: 14px;
      line-height: 19px;
      color: rgba(45, 45, 45, 1);
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      & span {
        margin-left: 5px;
      }
    }
  }

  & .additional__copies {
    margin-top: 40px;

    &>p {
      margin: 0px;
      margin-bottom: 0px;

      font-size: 16px;
      line-height: 21px;
      color: rgba(45, 45, 45, 1);
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 20px;

      & span {
        margin-left: 10px;
      }
    }
  }

  & .float__controls {
    margin-top: 60px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    &>.float__cancel {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 125px;
      min-height: 45px;
      border: 1px solid #0e7d7d;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      font-size: 14px;
      line-height: 14px;
      font-weight: 600;
      transition: 0.4s ease all;
      color: rgba(14, 125, 125, 1);

      &:active,
      &:focus {
        border-color: rgba(14, 125, 125, 0.3);
      }

      &:hover {
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      }
    }

    &>.float__add {
      margin-right: 15px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 110px;
      min-height: 45px;
      background: #0e7d7d;
      box-sizing: border-box;
      box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
      border-radius: 3px;
      transition: 0.4s ease all;
      font-size: 14px;
      line-height: 14px;
      color: rgba(255, 255, 255, 1);
      font-weight: 600;

      &:hover {
        background: #076969;
      }
    }
  }

  &>.float__inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    & .float__switcher {
      &>p {
        margin-top: 0px;
        font-size: 16px;
        line-height: 21px;
        color: rgba(45, 45, 45, 1);
        font-weight: bold;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        & span {
          margin-left: 10px;

          & img {
            min-width: 15px;
          }
        }
      }
    }

    &>.cost__info {
      padding: 15px;
      background: #e7f2f2;
      border-radius: 40px;
      margin-bottom: 20px;

      &>p {
        margin: 0px;
        font-size: 14px;
        line-height: 14px;
        font-weight: 400;

        & span {
          font-weight: bold;
        }
      }
    }

    &>.document__certif--title {
      margin-bottom: 20px;

      &>p {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 18px;
        line-height: 24px;
        color: rgba(45, 45, 45, 1);
        font-weight: bold;
        margin: 0px;

        &>span {
          display: inline-flex;
          margin-left: 10px;
        }
      }
    }
  }
}

header {
  &.simplified__header {
    & .outer__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 15px;
      padding-right: 15px;

      & .header__info {
        &>p {
          margin: 0px;
          font-size: 13px;
          line-height: 17px;
          font-weight: 400;

          & a {
            color: rgba(14, 125, 125, 1);
            font-weight: bold;
            text-decoration: underline;
          }

          & span {
            font-weight: bold;
          }
        }
      }
    }
  }
}

.simplified__block {
  & .translation__correct--wrapper {
    & .information__checkbox {
      margin-bottom: 20px;
    }

    & .translation__correct--field {
      max-width: 400px;
      width: 100%;

      & .field__custom {
        width: 100%;

        &>textarea {
          width: 100%;
          height: 80px;
          background: #f2f2f2;
          border-radius: 3px;
          resize: none;
          padding: 10px;
        }

        &>p {
          margin-top: 0px;
          margin-bottom: 12px;
          font-size: 14px;
          line-height: 20px;
          font-weight: 600;
          color: rgba(45, 45, 45, 1);
        }
      }
    }

    &>h2 {
      margin-top: 0px;
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 24px;
      color: rgba(45, 45, 45, 1);
      font-weight: bold;
    }
  }

  & .summary__project--info {
    margin-bottom: 20px;

    & ul {
      &>li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 14px;
        font-weight: 400;
        color: rgba(45, 45, 45, 1);

        &:last-child {
          margin-bottom: 0px;
        }

        &>span {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;

          & img {
            min-width: 14px;
          }
        }
      }
    }
  }

  & .get__started {
    & .started__form {
      max-width: 320px;
      margin-bottom: 30px;
    }

    & .started__note {
      max-width: 510px;
      padding: 15px;

      &>p {
        margin: 0px;
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);

        & span {
          font-weight: bold;
        }
      }

      &>a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 200px;
        min-height: 40px;
        background: #ebf1f1;
        border-radius: 3px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        color: rgba(14, 125, 125, 1);
        text-decoration: none;
        transition: 0.4s ease all;

        &:hover {
          opacity: 0.7;
        }
      }

      border: 1px solid #e3e3e3;
      border-radius: 10px;
    }

    & .head__started {
      margin-bottom: 30px;

      &>h2 {
        margin-top: 0px;
        margin-bottom: 5px;
        font-size: 18px;
        line-height: 24px;
        color: rgba(45, 45, 45, 1);
        font-weight: bold;
      }

      &>p {
        margin: 0px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: rgba(45, 45, 45, 1);
      }
    }
  }

  & .summary__project {
    & .summary__estimation {
      min-height: 90px;
      margin-bottom: 15px;
      border-bottom: 1px solid #e7e7e7;

      &>p {
        margin: 0px;
        font-size: 14px;
        line-height: 20px;
        color: rgba(45, 45, 45, 1);
        font-weight: bold;
      }
    }
  }

  & .summary__total--info {
    &>p {
      &.summary__empty {
        color: rgba(14, 125, 125, 1) !important;
      }
    }
  }

  & .element__progress.done__progress {
    background-color: #e7f2f2 !important;
  }
}

.checkout__long.simplified__block {
  & .summary__empty {
    padding-right: 10px !important;
  }

  & .summary__element>.summary__left>ul>li {
    color: rgba(158, 158, 158, 1) !important;
  }

  & .summary__project--wrapper {
    min-height: 105px;
    border-bottom: 1px solid rgba(231, 231, 231, 1);
    margin-bottom: 15px;

    & .summary__element {
      &>.summary__left {
        &>h2 {
          margin-bottom: 0px !important;
        }

        &>ul {
          &>li {
            &>span {
              display: inline-flex;
              margin-top: 2px;
              font-size: 14px;
              line-height: 20px;
              color: rgba(45, 45, 45, 1);
              font-weight: bold;
            }

            &>p {
              margin: 0px;
              font-size: 14px;
              line-height: 20px;
              color: rgba(157, 157, 157, 1);

              & span {
                font-weight: bold;
              }
            }
          }
        }
      }

      & .summary__right>p {
        margin-right: 0px !important;
        padding-right: 10px;
      }

      &:last-child {
        margin-bottom: 0px !important;
      }

      border-bottom: 0px !important;

      &:nth-child(1) {
        border-bottom: 0px !important;
      }
    }
  }
}

.summary__total--info.filled {
  padding-right: 10px;

  // margin-top:65px;
  // padding-top:15px;
  // border-top:1px solid #E7E7E7;
  &>p {
    font-size: 16px !important;
    line-height: 20px !important;
    color: rgba(14, 125, 125, 1) !important;
    font-weight: bold !important;
  }
}

.translation__wrapper--picker {
  & .translation__picker--element {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0px;
    }

    & .picker__choose--grid {
      display: flex;
      justify-content: center;

      &>.elem__choose--grid {
        & .choose__grid--switch {
          padding-top: 8px;
        }

        border: 2px solid #e3e3e3;
        width: 100%;
        padding: 20px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-right: -2px;
        transition: 0.4s ease all;
        position: relative;
        cursor: pointer;

        &.active__choose--grid {
          z-index: 2;
          border-color: rgba(14, 125, 125, 1);
        }

        &>.choose__grid--info {
          &>p {
            margin: 0px;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            color: rgba(59, 59, 59, 1);

            & span {
              font-weight: 600;
            }
          }

          &>h6 {
            min-height: 33px;

            &>p {
              font-size: 14px;
              line-height: 22px;
              font-weight: bold;
              min-height: 38px;
              margin-right: 5px;
              margin-top: 0px;
              margin-bottom: 0px;
              min-height: 0px;
            }

            margin-top: 0px;
            margin-bottom: 10px;

            align-items: center;
            flex-wrap: wrap;
            vertical-align: center;
            display: flex;

            & span {
              padding: 3px 10px;
              background: rgba(235, 241, 241, 1);
              border-radius: 5px;
              color: rgba(99, 176, 176, 1);
              font-weight: 600;
              margin-left: 5px;
              margin: 5px 3px;
              margin-left: 0px;
              display: inline-block;
            }

            color: rgba(14, 125, 125, 1);
          }
        }

        &:last-child {
          border-bottom-right-radius: 10px;
          border-top-right-radius: 10px;
        }

        &:nth-child(1) {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
      }
    }

    & .translation__picker--title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 10px;

      &>span {
        display: inline-flex;
        width: 100%;
        border-radius: 5px;
        height: 1px;
        background-color: #e7e7e7;
      }

      &>h2 {
        margin: 0px;
        margin-right: 15px;
        white-space: nowrap;
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;
        color: rgba(45, 45, 45, 1);
      }
    }
  }
}

.shipping__information--section {
  & .shipping__form {
    max-width: 550px;
    width: 100%;

    & form {
      &>.phone__group {
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        &>.field__custom {
          &:nth-child(1) {
            width: 100%;
            max-width: 260px;
          }

          &:nth-child(2) {
            max-width: 280px;
          }

          margin-right: 10px;
          margin-bottom: 0px;

          &:last-child {
            margin-right: 0px;
          }
        }
      }

      &>.city__group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        &>.field__custom {
          margin-right: 10px;

          & .select__dropdown {
            margin-top: 0px !important;
          }

          margin-bottom: 0px;

          & .select__label {
            background-color: #f5f5f5;

            & span {
              font-weight: 600 !important;
            }
          }

          &:nth-child(3) {
            margin-right: 0px;
          }

          &:nth-child(2) {
            width: 100%;
            max-width: 210px;
          }

          &:nth-child(1) {
            width: 100%;
            max-width: 200px;
          }
        }
      }

      &>.adress__group {
        display: flex;
        margin-bottom: 10px;

        &>.field__custom {
          margin-bottom: 0px;

          &:nth-child(1) {
            width: 100%;
            max-width: 320px;
            margin-right: 10px;
          }

          &:nth-child(2) {
            max-width: 220px;
            width: 100%;
          }
        }
      }

      &>.field__custom {
        margin-bottom: 10px;
      }
    }
  }

  & .shipping__information--head {
    margin-bottom: 20px;

    &>h2 {
      margin-top: 0px;
      margin-bottom: 4px;
      font-size: 18px;
      line-height: 24px;
      color: rgba(45, 45, 45, 1);
      font-weight: bold;
    }

    &>p {
      margin: 0px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: rgba(45, 45, 45, 1);
    }
  }
}

.simplified__block {
  & .payment__method {
    margin-top: 30px;

    & .payment__method--grid {
      &>.elem__payment--method {
        padding: 10px 15px;
        padding-left: 10px;
      }
    }
  }
}

.order__confirm {
  min-height: 760px;
  padding-top: 25px;
  padding-bottom: 40px;
  width: 100%;
  background: #ffffff;
  box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
  padding-right: 15px;
  flex-direction: column;

  &>.order__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &>.order__details {
      margin-top: 30px;

      & .back__order {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        &>a {
          align-items: center;
          justify-content: center;
          width: 100%;
          max-width: 290px;
          min-height: 40px;
          font-size: 14px;
          line-height: 20px;
          color: rgba(255, 255, 255, 1);
          font-weight: bold;
          text-decoration: none;
          display: inline-flex;
          background: #0e7d7d;
          box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05), 0px 4px 5px rgba(0, 0, 0, 0.05);
          border-radius: 3px;
          transition: 0.4s ease all;

          &:hover {
            opacity: 0.7;
          }
        }
      }

      &>span {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        line-height: 19px;
        color: rgba(45, 45, 45, 1);
        text-align: center;
        font-weight: 400;
        font-style: italic;
        margin-bottom: 45px;
      }

      &>.order__email--wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;

        &>.order__email {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 15px 20px;
          border: 1px solid #e3e3e3;
          border-radius: 5px;

          &>a {
            font-size: 14px;
            line-height: 20px;
            color: rgba(14, 125, 125, 1);
            font-weight: bold;
            text-transform: uppercase;
            text-decoration: none;
          }

          &>p {
            font-size: 16px;
            line-height: 24px;
            color: rgba(45, 45, 45, 1);
            font-weight: 600;
            margin: 0px;
            margin-right: 10px;
          }
        }
      }

      &>p {
        text-align: center;
        margin: 0px;
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: rgba(45, 45, 45, 1);
      }
    }

    &>p {
      margin: 0px;
      padding: 15px 20px;
      background: #e7f2f2;
      border-radius: 5px;
      text-align: center;
      font-size: 16px;
      line-height: 24px;
      color: rgba(14, 125, 125, 1);
      font-weight: 600;
    }

    &>h2 {
      text-align: center;
      margin-top: 0px;
      margin-bottom: 20px;
      font-size: 26px;
      line-height: 34px;
      color: rgba(14, 125, 125, 1);
      font-weight: bold;

      & span {
        font-weight: 400;
      }
    }
  }

  &>.confirm__image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
    max-width: 405px;
    margin-left: auto;
    margin-right: auto;

    &>img {
      max-width: 405px;
      width: 100%;
    }
  }
}

.simplified__block {
  & .checkout__progress--section .element__progress {
    box-shadow: none;
  }
}

.element__progress--wrapper {
  width: 100%;
  padding: 2px;
  border-radius: 3px;
  background-color: #fff;

  &.done__progress {
    background-color: rgba(14, 125, 125, 0.2);
  }

  &.active__progress {
    background-color: rgba(14, 125, 125, 0.2);
  }

  -webkit-clip-path: polygon(0 0,
    calc(100% - 15px) 0%,
    100% 50%,
    calc(100% - 15px) 100%,
    0 99%,
    15px 50%);
  clip-path: polygon(0 0, calc(100% - 15px) 0%, 100% 50%, calc(100% - 15px) 100%, 0 99%, 15px 50%);
}

.element__progress--wrapper {
  &:nth-child(1) {
    clip-path: polygon(0 0, calc(100% - 15px) 0%, 100% 50%, calc(100% - 15px) 100%, 0 99%, 0px 50%);

    &>.element__progress {
      clip-path: polygon(0 0,
          calc(100% - 15px) 0%,
          100% 50%,
          calc(100% - 15px) 100%,
          0 99%,
          0px 50%);
    }
  }
}

.simplified__block {
  & .pair__picker .pair__container>.head__pair>.pair__double {
    &>.pair__elem {
      max-width: 100%;

      & .group__tag--checkbox>.group__target--container {
        padding-left: 15px;
        min-height: 40px;
      }

      & .select__label {
        border-color: #fff !important;
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);

        & span {
          color: #75758b !important;
        }
      }
    }
  }
}

.table__wrapper table.table__common tr td {
  & .language__pairs {
    display: flex;
    justify-content: center;
    align-items: center;

    &>p {
      display: flex;
      align-items: center;
      color: #2d2d2d;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      justify-content: center;
      white-space: nowrap;

      & a {
        display: flex;
        align-items: center;
        margin-left: 10px;
        justify-content: flex-start;
      }

      & img {
        max-width: 18px;
      }
    }
  }
}

.double__check--input {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &>a {
    margin-left: 15px;
    font-size: 16px;
    line-height: 21px;
    color: rgba(63, 136, 197, 1);
    font-weight: 600;
    text-decoration: underline;
  }
}

.employers-page__heading.task__heading>.ordering__form--head {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-right: 0px;

  & .menu__permissions {
    & .permissions-control-dropdown__toggle {
      padding: 0px;
      border-radius: 3px;

      & .burger {
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 44px;
        min-height: 40px;
        border-radius: 5px;
      }
    }

    &>a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 44px;
      min-height: 40px;
      background: #ffffff;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
    }
  }
}

.modal__regular {
  &>a {
    right: calc(100% - 59px);
    opacity: 1;
  }

  &>.inner__modal--regular {
    right: 0px;
    max-width: 100%;
    width: calc(100% - 70px);

    & .price__modal--info {
      & .price__main {
        & .table__wrapper {
          padding-bottom: 10px;
          border-bottom: 1px solid #e3e3e3;

          & .table__common {
            & .padd__text1 {
              margin-right: 160px;
            }

            & .padd__text2 {
              margin-right: 22px;
            }

            & .padd__text3 {
              margin-right: 61px;
            }

            & tr {
              &:nth-child(3) {
                &>td {
                  &>div.source__counter {
                    top: -28px;
                    height: calc(100% + 74px) !important;
                  }
                }
              }
            }

            & td {
              padding-left: 0px;
            }

            & .number__one {
              &>td {
                padding-top: 10px;
                padding-bottom: 7px;

                & span {
                  font-size: 12px;
                  line-height: 18px;
                  color: rgba(45, 45, 45, 1);
                  font-weight: 400;
                }
              }
            }

            & td {
              &.one__more {
                padding-top: 19px !important;
                border-top: 1px solid #e3e3e3;
                padding-bottom: 15px !important;

                &>a {
                  display: inline-flex;
                  align-items: center;
                  justify-content: flex-start;
                  font-size: 14px;
                  line-height: 21px;
                  color: rgba(14, 125, 125, 1);
                  font-weight: 600;

                  & span {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 10px;
                  }
                }
              }

              & .fee__select {
                & .select__label {
                  min-height: 40px;
                  min-width: 85px;
                  border: 1px solid #e3e3e3;
                  border-radius: 3px;
                  padding-left: 10px;
                  padding-right: 30px;

                  &:after {
                    right: 8px !important;
                  }

                  &>span {
                    font-size: 12px;
                    line-height: 18px;
                    color: rgba(63, 136, 197, 1);
                  }

                  &:after {
                    right: 12px;
                  }
                }
              }

              & .custom__select .select {
                padding-right: 0px;
              }

              & .wrapper__field--row {
                display: flex;
                align-items: flex-start;

                &>.wrapper__field {
                  margin-right: 20px;
                }
              }

              padding-top: 0px;

              & .wrapper__field {
                &:last-child {
                  margin-right: 0px;
                }

                &.wrapper__field--longspace {
                  margin-right: 25px;
                }

                & .select__time {
                  & button.select__label {
                    min-width: 110px;
                    min-height: 40px;
                    padding-left: 10px;
                    border: 1px solid #dadada;
                    border-radius: 3px;
                  }
                }

                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 10px;

                &:last-child {
                  margin-bottom: 0px;
                }

                &>.default__pricing--input {
                  margin-right: 30px;

                  &:last-child {
                    margin-right: 0px;
                  }

                  &.long__input {
                    min-width: 110px;

                    &>input {
                      min-width: 110px;
                    }
                  }

                  &.short__input {
                    min-width: 90px;

                    &>input {
                      min-width: 90px;
                    }
                  }

                  &>input {
                    border: 1px solid #dadada;
                    border-radius: 5px;
                    min-height: 40px;
                    font-size: 14px;
                    line-height: 20px;
                    color: rgba(45, 45, 45, 1);
                    font-weight: 400;
                    padding-left: 10px;
                    padding-right: 10px;
                    max-width: 90px;

                    &::-webkit-input-placeholder {
                      /* Chrome/Opera/Safari */
                      color: rgba(45, 45, 45, 1);
                    }

                    &::-moz-placeholder {
                      /* Firefox 19+ */
                      color: rgba(45, 45, 45, 1);
                    }

                    &:-ms-input-placeholder {
                      /* IE 10+ */
                      color: rgba(45, 45, 45, 1);
                    }

                    &:-moz-placeholder {
                      /* Firefox 18- */
                      color: rgba(45, 45, 45, 1);
                    }
                  }

                  &:last-child {
                    margin-right: 0px;
                  }
                }
              }

              & .select__label {
                border-radius: 5px !important;
              }

              & .select__time {
                & .select__label {
                  padding-right: 18px;

                  &>span {
                    max-width: 80px;
                    white-space: nowrap;
                    overflow: hidden !important;
                    text-overflow: ellipsis;
                  }

                  &:after {
                    right: 7px;
                  }
                }
              }

              & .default__unit--wrapper {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                & .words__dropdown {
                  & .custom__select {
                    & .select__dropdown {
                      margin-top: 0px;
                    }

                    & .select__label:after {
                      right: 8px;
                    }

                    & .select__label {
                      &>span {
                        font-size: 12px;
                        line-height: 18px;
                        color: rgba(63, 136, 197, 1);
                        text-transform: uppercase;
                      }
                    }

                    & .select {
                      min-width: 100px;
                      max-width: 100px;
                      min-height: 40px;
                      border: 1px solid #dadada;
                      border-radius: 5px;

                      &>button {
                        &>span {
                          width: 100%;
                          text-align: left;
                          white-space: nowrap;
                          overflow: hidden !important;
                          text-overflow: ellipsis;
                        }

                        min-width: 90px;
                        min-height: 40px;
                        padding-left: 10px;
                        padding-right: 25px;
                      }
                    }
                  }
                }
              }

              &:nth-child(2) {
                padding-left: 0px;
                padding-top: 0px;
              }

              & .unit__field {
                margin-right: 10px;

                &>input {
                  min-width: 70px;
                  max-width: 70px;
                  min-height: 40px;
                  padding-left: 10px;
                  padding-right: 10px;
                  border: 1px solid #dadada;
                  font-size: 14px;
                  line-height: 20px;
                  color: rgba(45, 45, 45, 1);
                  font-weight: 400;
                  border-radius: 5px;
                }
              }

              &:nth-child(1) {
                padding-top: 0px;
                padding-left: 45px;

                & .group__tag--checkbox {
                  &>.group__target--container {
                    min-height: 40px;
                    padding-left: 15px;
                  }

                  max-width: 170px;

                  &>.group__target--container>input {
                    max-width: 125px;
                    color: rgba(45, 45, 45, 1);
                    font-size: 12px;
                    font-weight: 600;

                    &::-webkit-input-placeholder {
                      /* Chrome/Opera/Safari */
                      color: rgba(45, 45, 45, 1);
                    }

                    ::-moz-placeholder {
                      /* Firefox 19+ */
                      color: rgba(45, 45, 45, 1);
                    }

                    :-ms-input-placeholder {
                      /* IE 10+ */
                      color: rgba(45, 45, 45, 1);
                    }

                    :-moz-placeholder {
                      /* Firefox 18- */
                      color: rgba(45, 45, 45, 1);
                    }
                  }
                }
              }

              & .source__counter {
                position: absolute;
                left: 0px;
                top: 0px;
                width: 30px;
                z-index: 2;
                background-color: rgba(227, 227, 227, 0.5);
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                height: 100%;
                height: calc(100% + 48px) !important;

                &>.remove__main {
                  position: absolute;
                  left: 0px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  right: 0px;
                  top: 12px;
                  margin-left: auto;
                  margin-right: auto;
                }

                &>span {
                  display: flex;
                  width: 100%;
                  justify-content: center;
                  align-items: center;
                  height: 100%;
                  font-size: 14px;
                  line-height: 21px;
                  color: rgba(14, 125, 125, 1);
                  font-weight: bold;
                }
              }
            }

            & th {
              padding-left: 0px;
              padding-top: 10px;
              padding-bottom: 10px;
              border-bottom: 1px solid rgba(227, 227, 227, 1);

              & h5 {
                margin: 0px;
                color: rgba(45, 45, 45, 1);
              }
            }
          }
        }

        &>h2 {
          margin-top: 0px;
          margin-bottom: 20px;
          font-size: 25px;
          line-height: 34px;
          color: rgba(14, 125, 125, 1);
          font-weight: bold;
        }
      }
    }
  }
}

.dropdown__pricing--spacer {
  margin-top: 40px !important;
  transform: none !important;
}

.price__main {
  & .table__wrapper table.table__common {
    padding-bottom: 150px;
    border-collapse: inherit;
  }
}

.price__main {
  & .group__target--container {
    background-color: #f5f5f5;

    &>.el__field--target {
      background-color: rgba(255, 255, 255, 0.8);
    }

    &>input {
      background-color: #f5f5f5;
    }
  }

  & .group__tag--checkbox .target__dropdown>ul {
    max-height: 220px;
  }
}

.modal__regular>.inner__modal--regular .price__modal--info .price__main .table__wrapper {
  border-bottom: 0px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.table__other {
  padding-top: 20px;
  margin-top: -160px;
  position: relative;
  border-top: 1px solid rgba(227, 227, 227, 1);
}

.more__combination {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0px;
  margin-top: 25px;
  align-items: center;

  &>a {
    padding: 8px 20px;
    display: inline-flex;
    align-items: center;
    min-height: 40px;
    font-size: 14px;
    line-height: 21px;
    color: rgba(14, 125, 125, 1);
    font-weight: 600;
    border: 1px solid rgba(227, 227, 227, 1);
    border-radius: 3px;
    justify-content: center;
    transition: 0.4s ease all;

    &:hover {
      border-color: #0e7d7d;
    }

    & span {
      display: inline-flex;
      margin-right: 10px;
      align-items: center;
      justify-content: center;

      & img {
        width: 14px;
      }
    }
  }
}

.other__options {
  margin-top: 35px;

  & .other__option--wrapper {
    & .other__option--elem {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0px;
      }

      &>.option__inner--element {
        margin-bottom: 15px;
        max-width: 640px;

        &:last-child {
          margin-bottom: 0px;
        }

        &>.option__desc {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          &>textarea {
            max-width: 215px;
            min-width: 215px;
            height: 70px;
          }
        }

        &>.option__row {
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          & .price__select {
            & .page__select {
              & button {
                min-width: 80px;
                min-height: 40px;
                border: 1px solid #dadada;
                box-sizing: border-box;
                border-radius: 5px;
                padding-left: 10px;
                padding-right: 20px;

                &:after {
                  right: 6px;
                }

                &>span {
                  font-size: 12px;
                  line-height: 18px;
                  color: rgba(63, 136, 197, 1);
                  font-weight: 400;
                }
              }
            }
          }

          & .price__unit {
            margin-right: 10px;

            &>input {
              min-width: 125px;
              max-width: 125px;
            }
          }

          & .price__field {
            margin-right: 10px;

            &>input {
              min-width: 125px;
              max-width: 125px;
            }
          }

          & .option__name {
            margin-right: 10px;

            &>input {
              min-width: 215px;
              max-width: 215px;
            }
          }
        }
      }

      &>.option__name {
        margin-bottom: 10px;

        &>input {
          min-width: 215px;
          max-width: 215px;
        }
      }
    }
  }

  & .other__option--more {
    margin-top: 15px;

    &>a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      // min-width:175px;
      // min-height:40px;
      font-size: 14px;
      line-height: 21px;
      color: #0e7d7d;
      font-weight: 600;
      // border: 1px solid #E3E3E3;
      transition: 0.4s ease all;
      border-radius: 3px;

      &:hover {
        border-color: #0e7d7d;
      }

      & span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;

        &>img {
          min-width: 14px;
        }
      }
    }
  }

  &>p {
    margin-top: 0px;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 21px;
    color: rgba(45, 45, 45, 1);
    font-weight: bold;
  }
}

.different__combination {
  &>p {
    margin-top: 0px;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 22px;
    color: rgba(45, 45, 45, 1);
    font-weight: bold;
  }

  &>a {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    line-height: 21px;
    color: rgba(14, 125, 125, 1);
    font-weight: 600;
    justify-content: flex-start;

    &>span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;

      &>img {
        min-width: 14px;
      }
    }
  }

  & .different__combo--inputs {
    margin-bottom: 20px;

    & .elem__different--combo {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 10px;
      position: relative;
      max-width: 240px;

      &>a {
        position: absolute;
        right: -20px;
      }

      &:last-child {
        margin-bottom: 0px;
      }

      &>.different__price {
        &>input {
          min-width: 90px;
          max-width: 90px;
        }
      }

      &>.different__size {
        margin-right: 10px;

        &>input {
          min-width: 130px;
          max-width: 90px;
          min-height: 40px;
        }
      }
    }
  }
}

.price__modal--info {
  & .custom__select .select__dropdown {
    margin-top: 0px;

    & .simplebar-content-wrapper {
      height: none !important;
    }
  }

  & .simplebar-content:before,
  .simplebar-content:after {
    content: none;
  }
}

.table__other--controls {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 40px;

  &>.table__cancel {
    margin-right: 15px;
    min-width: 125px;
    min-height: 45px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #0e7d7d;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    color: rgba(14, 125, 125, 1);
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    transition: 0.4s ease all;

    &:focus {
      color: #ffffff !important;
      border-color: #0a5858;
      background-color: #0a5858;
      box-shadow: 0 0 0 0.2rem rgba(14, 125, 125, 0.5);
    }

    &:active {
      background-color: #0a5858;
      color: #fff !important;
    }

    &:hover {
      background-color: #0a5858;
      color: #fff !important;
    }
  }

  &>.table__create {
    margin-right: 15px;
    min-width: 125px;
    min-height: 45px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #0e7d7d;
    border: 2px solid #0e7d7d;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    color: #fff;
    transition: 0.4s ease all;

    &:active {
      color: #ffffff;
      background-color: #094f4f;
    }

    &:focus {
      color: #ffffff;
      background-color: #0a5858;
      box-shadow: 0 0 0 0.2rem rgba(14, 125, 125, 0.5);
    }

    &:hover {
      background-color: #0a5858;
    }
  }
}

.more__variant {
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &>a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 175px;
    min-height: 40px;
    font-size: 14px;
    line-height: 21px;
    color: rgba(14, 125, 125, 1);
    font-weight: 600;
    text-decoration: none;
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    transition: 0.4s ease all;

    &:hover {
      border-color: #0e7d7d;
    }

    & span {
      margin-right: 10px;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &>img {
        min-width: 14px;
      }
    }
  }
}

.modal__regular.modal__pricing {
  &>a {
    opacity: 0;
  }

  &>.inner__modal--regular {
    right: calc(-100% + 70px);
  }
}

.created__other {
  position: relative;
  max-width: 580px;

  &>a {
    position: absolute;
    top: 10px;
    right: -28px;
  }
}

.created__other--full {
  max-width: 580px;
  position: relative;

  &>.removefull__other {
    position: absolute;
    top: 9px;
    left: 229px;
    right: auto;
  }
}

.wrapper__field {
  position: relative;

  &>.remove__field--row {
    position: absolute;
    left: -30px;
  }
}

.form__pricing--wrapper {
  margin-bottom: -150px;
  width: 100%;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.top__form--pricing {
  padding-bottom: 150px;
  min-width: 1400px;
  max-width: 100%;
  width: 100%;

  &>.top__form--head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(227, 227, 227, 1);
    position: relative;

    & h6 {
      margin: 0px;
      color: #2d2d2d;
    }

    &>.top__form--titlelong {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &>h6 {
        white-space: nowrap;
        font-weight: 600;

        &:nth-child(1) {
          min-width: 65px;
        }
      }
    }

    &>.top__form--titlelong {
      min-width: 695px;
      width: 167%;

      &>h6 {
        width: 100%;

        &:nth-child(1) {
          width: 26%;
          min-width: 245px;
        }

        &:nth-child(2) {
          width: 11%;
        }

        &:nth-child(3) {
          width: 60%;
        }
      }
    }

    &>.top__form--title {
      &:nth-child(1) {
        min-width: 230px;
        width: 41%;
      }

      &:nth-child(2) {
        min-width: 195px;
        width: 34%;
      }
    }
  }
}

.price__main {
  & .more__size {
    padding-top: 20px;
    margin-top: 10px;
    border-top: 1px solid rgba(227, 227, 227, 1);

    &>a {
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      line-height: 21px;
      color: #0e7d7d;
      font-weight: 600;
      justify-content: flex-start;

      & span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;

        &>img {
          min-width: 14px;
        }
      }
    }
  }

  & .price__select {
    &.field__custom {
      & .select {
        &>button.select__label {
          border: 1px solid #c5c5c5 !important;
        }
      }
    }
  }

  & .field__custom {
    margin-bottom: 0px;

    &>textarea {
      border: 1px solid #c5c5c5;
    }

    &>input {
      border: 1px solid #c5c5c5;
    }
  }

  & .field__custom {
    & .custom__select {
      &.page__select button {
        background-color: #f5f5f5 !important;
        border: 0px !important;
      }
    }
  }
}

.content__form--container {
  padding-top: 10px;
  padding-bottom: 30px;

  & .elem__form--pricing {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;

    &>.column__form--pricing {
      position: relative;
      min-width: 230px;
      width: 41%;
      padding-left: 0px;
      padding-top: 30px;

      & .column__combination {
        padding-left: 45px;

        &>.group__tag--checkbox {
          width: 100%;
          max-width: 170px;

          & input {
            width: 100%;
            color: #2d2d2d;
            font-size: 12px;
            font-weight: 600;
          }
        }
      }

      &>span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0px;
        top: 0px;
        height: 100%;
        width: 30px;
        background-color: rgba(227, 227, 227, 0.5);
        font-size: 14px;
        line-height: 21px;
        color: rgba(14, 125, 125, 1);
        font-weight: bold;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.size__prize--group {
  min-width: 695px;
  width: 167%;
  padding-top: 30px;

  & .elem__size--prize--group {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0px;
    }

    & .wrapper__field {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &>.field__custom {
        &:last-child {
          margin-right: 0px;
        }
      }

      &>.field__custom.short__input {
        min-width: 90px;
        max-width: 90px;
      }

      &>.field__custom.long__input {
        margin-right: 10px;

        &:last-child {
          margin-right: 0px;
        }

        min-width: 125px;
        max-width: 125px;
      }
    }

    & .wrapper__field--row {
      display: flex;
      justify-content: flex-start;
      width: 60%;

      & .wrapper__field {
        margin-right: 20px;

        & .custom__select {
          &.fee__select {
            & .select__label {
              min-width: 85px;
              max-width: 85px;
              padding-left: 10px;
              padding-right: 25px;
              background-color: #f5f5f5;

              &>span {
                font-size: 12px;
                line-height: 18px;
                color: #3f88c5;
              }
            }
          }

          & .select__label {
            min-width: 125px;
            max-width: 125px;
            min-height: 40px;
            padding-left: 10px;
            padding-right: 25px;
            background-color: #f5f5f5;
            border: 1px solid #c5c5c5;

            &:after {
              right: 6px;
            }
          }
        }

        &:last-child {
          margin-right: 0px;
        }

        &>.field__custom.short__input {
          min-width: 90px;
          max-width: 90px;
        }

        &>.field__custom.long__input {
          margin-right: 10px;

          &:last-child {
            margin-right: 0px;
          }

          min-width: 125px;
          max-width: 125px;
        }
      }
    }

    &>.wrapper__field {
      &:nth-child(1) {
        width: 26%;
        min-width: 245px;
      }

      &:nth-child(2) {
        width: 11%;
        min-width: 90px;
      }
    }
  }
}

.column__units {
  display: flex;
  align-items: flex-start;
  padding-top: 30px;
  justify-content: flex-start;
  min-width: 195px;
  width: 34%;

  & .field__custom {
    width: auto;

    &>input {
      border: 1px solid #c5c5c5;
    }
  }

  &>.matt__group {
    &>.custom__select {
      & button.select__label {
        min-width: 100px;
        max-width: 100px;
        min-height: 40px;
        padding-left: 10px;
        padding-right: 25px;
        background-color: #f5f5f5;
        border: 1px solid #c5c5c5;

        &:after {
          right: 7px;
        }

        &>span {
          width: 100%;
          text-align: left;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
          font-size: 12px;
          line-height: 18px;
          color: #3f88c5;
          text-transform: uppercase;
        }
      }
    }
  }

  &>.unit__group {
    margin-right: 10px;

    &>input {
      min-width: 70px;
      max-width: 70px;
    }
  }
}

.select__once--field {
  width: 100%;
  position: relative;

  &>input {
    cursor: pointer;
  }

  & .select__main--dropdown {
    position: absolute;
    top: 56px;
    left: 0px;
    width: 100%;
    padding: 10px;
    background: #ffffff;
    box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    z-index: 3;

    & .search__select--dropdown {
      max-height: 360px;
      overflow-y: auto;
      padding-right: 5px;

      &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-track {
        background-color: #e9e9e9;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #aeaeae;
      }

      &>ul {
        padding: 0px;
        list-style-type: none;
        margin: 0px;

        &>li {
          display: flex;
          width: 100%;
          margin-bottom: 1px;

          &.active__select--dropdown {
            &>a {
              background: url('../images/whitecheck.svg') !important;
              background-repeat: no-repeat !important;
              background-size: auto !important;
              background-position: 95% center !important;
              background-color: #0e7d7d !important;
              border-radius: 4px !important;
              color: #fff !important;
            }
          }

          &>a {
            display: flex;
            width: 100%;
            height: 35px;
            padding: 5px 10px;
            justify-content: flex-start;
            align-items: center;
            font-size: 13px;
            line-height: 21px;
            font-weight: 600;
            color: #2d2d2d;
            text-decoration: none;

            &:hover {
              background: #ebf1f1;
              border-radius: 4px;
              color: #0e7d7d;
            }
          }

          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }

    & .search__select--field {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      height: 40px;
      margin-bottom: 10px;

      &>input {
        width: 100%;
        height: 40px;
        padding-left: 10px;
        padding-right: 10px;
        color: #000;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        outline: none;
        border: 1px solid #dddddd;
        border-radius: 3px;
      }
    }
  }

  &:before {
    content: '';
    position: absolute;
    right: 10px;
    width: 12px;
    height: 15px;
    background: url('../images/servicearrow.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    top: 50%;
    -webkit-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
  }

  &>input {
    width: 100%;
    height: 36px;
    background: #ffffff;
    border-radius: 5px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 12px;
    color: #444444;
    font-weight: 600;
    outline: none;
    border: 0px;
  }
}

.select__two {
  & .select__multi--field {
    position: relative;

    & .multi__dropdown {
      position: absolute;
      left: 0px;
      width: 100%;
      top: 56px;
      padding: 10px;
      background: #ffffff;
      box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
      border-radius: 3px;
      z-index: 3;
    }

    & .multi__dropdown {
      & .multi__list {
        max-height: 360px;
        overflow-y: auto;
        padding-right: 5px;

        &::-webkit-scrollbar {
          width: 3px;
        }

        &::-webkit-scrollbar-track {
          background-color: #e9e9e9;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #aeaeae;
        }

        &>ul {
          padding: 0px;
          list-style-type: none;
          margin: 0px;
          width: 100%;

          &>li {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            &.multi__active {
              &>a {
                border-radius: 4px;
                background: url('../images/whitecheck.svg') !important;
                background-repeat: no-repeat !important;
                background-size: auto !important;
                background-position: 95% center !important;
                background-color: #0e7d7d !important;
                color: #fff;
              }
            }

            &>a {
              display: flex;
              width: 100%;
              min-height: 35px;
              border-radius: 4px;
              align-items: center;
              justify-content: flex-start;
              padding-left: 10px;
              padding-right: 10px;
              font-size: 13px;
              line-height: 21px;
              font-weight: 600;
              color: #2d2d2d;

              &:hover {
                background: #ebf1f1;
              }
            }

            &:last-child {
              margin-bottom: 0px;
            }

            margin-bottom: 1px;
          }
        }
      }

      & .multi__submit {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 12px;

        &>a {
          display: inline-flex;
          width: 100%;
          color: #0e7d7d;
          font-size: 13px;
          line-height: 21px;
          font-weight: 600;
          text-decoration: none;
          min-height: 35px;
          align-items: center;
          justify-content: center;
          background: #ffffff;
          border: 1px solid #0e7d7d;
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
          border-radius: 4px;
        }
      }

      & .multi__search {
        width: 100%;
        margin-bottom: 10px;

        &>input[type='text'] {
          width: 100%;
          height: 40px;
          background: #ffffff;
          border: 1px solid #dddddd;
          border-radius: 3px;
          padding-left: 10px;
          padding-right: 10px;
          font-size: 14px;
          line-height: 24px;
          font-weight: 400;
          color: #000;
        }
      }
    }

    & .multi__container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      min-height: 36px;
      background: #ffffff;
      border-radius: 5px;
      padding: 3px;
      flex-wrap: wrap;
      position: relative;
      padding-right: 30px;
      cursor: pointer;

      &>p {
        font-size: 12px;
        color: #444444;
        font-weight: 600;
        opacity: 0.8;
        padding-left: 15px;
      }

      &:before {
        content: '';
        position: absolute;
        right: 10px;
        width: 12px;
        height: 15px;
        background: url('../images/servicearrow.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        top: 50%;
        -webkit-transform: translate(0%, -50%);
        transform: translate(0%, -50%);
      }

      &>.multi__more {
        padding: 10px;
        background-color: #0e7d7d;
        border-radius: 3px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 33px;
        min-height: 30px;

        &>p {
          margin: 0px;
          font-size: 16px;
          color: #fff;
          font-weight: 400;
          line-height: 0px;
          position: absolute;
          margin-top: -8px;
        }
      }

      &>.multi__elem {
        margin-right: 3px;
        padding: 8px 10px;
        background: #0e7d7d;
        border-radius: 3px;
        display: flex;
        align-items: center;
        min-height: 30px;
        justify-content: flex-start;
        margin-bottom: 3px;
        position: relative;
        top: 1px;

        & p {
          margin: 0px;

          font-size: 12px;
          line-height: 12px;
          color: #ffffff;
          font-weight: 600;
        }

        & a {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-left: 10px;
        }

        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}

@media (max-width: 1740px) {
  .checkout__progress--section {
    & .element__progress {
      padding-right: 15px;

      & span {
        position: static;
        margin-right: 12px;
      }
    }
  }
}

@media (max-width: 1400px) {
  .simplified__block {
    & .checkout__progress--section {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  .checkout__progress--section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 10px;

    & .element__progress {
      & span {
        left: 25px;
        position: absolute;
      }
    }
  }
}

@media (max-width: 1200px) {
  .simplified__block {
    & .pair__picker .pair__container>.head__pair>.pair__double {
      flex-direction: column;

      &>.pair__spacer {
        margin: 15px 0px;
        transform: rotate(90deg);
      }
    }

    .checkout__progress--section {
      grid-template-columns: repeat(2, 1fr);

      display: flex;
      grid-column-gap: 0px;
      grid-row-gap: 0px;

      & .element__progress {
        width: 100% !important;
        max-width: 100% !important;

        &.active__progress {
          padding-left: 20px;
          padding-right: 20px;
          max-width: 100%;

          &>p {
            display: block;
          }

          & span {
            position: static;
            margin-left: 0px;
            margin-right: 10px;
          }
        }

        & span {
          left: 0px;
          right: 0px;
          margin-left: auto;
          margin-right: auto;
        }

        &>p {
          display: none;
        }
      }
    }
  }

  .translation__wrapper--picker .translation__picker--element .picker__choose--grid {
    flex-direction: column;

    &>.elem__choose--grid {
      border-radius: 0px !important;
      margin-right: 0px !important;
      margin-bottom: -2px !important;

      &:last-child {
        margin-bottom: 0px !important;
      }
    }
  }

  .pair__picker {
    & .pair__container {
      &>.head__pair {
        &>.pair__double {
          & .pair__elem {
            max-width: 180px;
          }
        }
      }
    }
  }

  .guest__payment--method {
    &>.chosen__element {
      flex-direction: column;

      &>.chosen__info--left {
        max-width: 100%;
        width: 100%;
      }

      &>.chosen__info--help {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;

        &>h2 {
          margin-top: 12px;
          margin-left: 0px;
        }
      }

      &>.spacer {
        margin: 12px 0px;
        width: 100%;
        height: 1px;
      }
    }

    &>.timeline__block {
      &>.choose__timeline--grid {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        &>.elem__choose--timeline {
          margin-right: 0px;
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  .checkout__long {
    & .content__guest {
      &>.guest__content--main {
        & .guest__checkout {
          & .guest__head {
            margin-bottom: 25px;
          }
        }
      }
    }
  }

  .complex__content {
    & .help__block {
      &>h2 {
        font-size: 22px;
        line-height: 31px;
      }
    }
  }
}

.elem__choose--timeline {
  & .datepicker {
    margin-right: 10px;

    &:after {}

    & .input {
      max-width: 165px;
      padding: 5px 8px;
      border: 0px;
    }
  }
}

@media (max-width: 991px) {
  .menu__permissions {
    & .permissions-control-dropdown__box {
      left: 50%;
      transform: translate(-50%, 0);
      right: auto;
    }
  }

  .simplified__block {
    & .checkout__progress--section {
      padding-top: 60px;
      position: relative;

      & .element__progress--wrapper {
        min-height: 50px;
        clip-path: polygon(0 0,
            calc(100% - 15px) 0%,
            100% 50%,
            calc(100% - 15px) 100%,
            0 99%,
            15px 50%) !important;

        &>.element__progress {
          clip-path: polygon(0 0,
              calc(100% - 15px) 0%,
              100% 50%,
              calc(100% - 15px) 100%,
              0 99%,
              15px 50%) !important;
          min-height: 48px;
          position: relative !important;
        }
      }

      & .active__progress {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        width: 100%;
      }
    }
  }

  header {
    &.simplified__header {
      & .outer__header {
        padding-left: 0px;
        padding-right: 0px;

        & .header__info {
          max-width: 420px;
          text-align: right;

          &>p {}
        }
      }
    }
  }

  .pair__picker .pair__container>.head__pair>.pair__double .pair__elem {
    max-width: 240px;
  }

  .checkout__long {
    & .content__guest {
      flex-direction: column;

      &>.guest__content--main {
        min-height: 0px;
      }

      &>.guest__content--side {
        width: 100%;
        max-width: 100%;
        margin-left: 0px;
        margin-top: 25px;
      }
    }
  }

  .checkout__long {
    width: calc(100% - 30px);
  }

  .checkout__progress--section {
    grid-template-columns: repeat(2, 1fr);
  }

  .complex__content {
    padding: 25px 15px;
    width: calc(100% - 30px);

    & .help__block {
      &>h2 {
        font-size: 19px;
        line-height: 28px;
      }
    }
  }

  .checkout__progress--section {
    display: flex;
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    & .element__progress {
      min-width: 90px;
      max-width: 90px;

      &.active__progress {
        padding-left: 20px;
        padding-right: 20px;
        max-width: 100%;

        &>p {
          display: block;
        }

        & span {
          position: static;
          margin-left: 0px;
          margin-right: 10px;
        }
      }

      & span {
        left: 0px;
        right: 0px;
        margin-left: auto;
        margin-right: auto;
      }

      &>p {
        display: none;
      }
    }
  }
}

.additional__float .uncheck__document>p {
  min-width: 15px;
}

.info__tooltip>img {
  min-width: 15px;
}

.additional__float .additional__copies>p {
  max-width: 85%;
}

.uncheck__file p span img {
  min-width: 28px;
}

@media (max-width: 767px) {
  .created__other--full>.removefull__other {
    right: -28px;
    left: auto;
  }

  .created__other--full {
    max-width: 100%;
  }

  .created__other {
    max-width: 100%;
  }

  .different__combination .different__combo--inputs .elem__different--combo {
    max-width: 100%;

    &>a {
      top: 50%;
      right: -25px;
      transform: translate(0%, -50%);
    }
  }

  .other__options .other__option--wrapper .other__option--elem {
    &>.option__inner--element {
      &>.option__desc {
        &>textarea {
          min-width: 0px;
          width: 100%;
          max-width: 100%;
        }
      }

      &>.option__row {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &>.price__select {
          width: 100%;
          max-width: 100%;
          min-width: 0px;

          & .custom__select .select {
            padding-right: 0px;
          }
        }

        &>.price__unit {
          margin-right: 0px;
          min-width: 0px;
          max-width: 100%;
          width: 100%;
          margin-bottom: 10px;

          &>input {
            min-width: 0px;
            max-width: 100%;
            width: 100%;
          }
        }

        &>.price__field {
          margin-right: 0px;
          min-width: 0px;
          max-width: 100%;
          width: 100%;
          margin-bottom: 10px;

          &>input {
            min-width: 0px;
            max-width: 100%;
            width: 100%;
          }
        }

        &>.option__name {
          margin-right: 0px;
          max-width: 100%;
          width: 100%;
          margin-bottom: 10px;

          &>input {
            width: 100%;
            min-width: 0px;
            max-width: 100%;
          }
        }
      }
    }

    &>.option__name {
      &>input {
        max-width: 100%;
        min-width: 0px;
        width: 100%;
      }
    }
  }

  .different__combination .different__combo--inputs .elem__different--combo {
    flex-direction: column;

    &>.different__price {
      width: 100%;

      &>input {
        width: 100%;
        max-width: 100%;
      }
    }

    &>.different__size {
      margin-right: 0px;
      margin-bottom: 10px;
      width: 100%;

      &>input {
        max-width: 100%;
        width: 100%;
      }
    }
  }

  .simplified__block {
    & .pair__picker .pair__container>.head__pair {
      padding-top: 25px !important;
    }
  }

  .order__confirm {
    padding-top: 15px;
    padding-bottom: 25px;
    min-height: 0px;
  }

  .order__confirm>.order__info>.order__details>span {
    margin-bottom: 25px;
  }

  .order__confirm>.order__info>h2 {
    font-size: 22px;
    line-height: 28px;
  }

  .guest__content--main .payment__method>h2 {
    margin-bottom: 15px;
  }

  .shipping__information--section .shipping__form {
    max-width: 100%;
  }

  .shipping__information--section .shipping__form form>.phone__group {
    flex-direction: column;

    &>.field__custom {
      width: 100% !important;
      max-width: 100% !important;
      margin-bottom: 10px;
      margin-right: 0px !important;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .shipping__information--section .shipping__form form>.city__group {
    flex-direction: column;

    &>.field__custom {
      width: 100% !important;
      max-width: 100% !important;
      margin-bottom: 10px;
      margin-right: 0px !important;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .shipping__information--section .shipping__form form>.adress__group {
    flex-direction: column;

    &>.field__custom {
      width: 100% !important;
      max-width: 100% !important;
      margin-bottom: 10px;
      margin-right: 0px;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .simplified__block .checkout__progress--section .element__progress {
    min-width: 70px;
    max-width: 70px;
  }

  .simplified__block .translation__correct--wrapper .translation__correct--field {
    max-width: 100%;
  }

  .simplified__block {
    & .get__started {
      & .head__started {
        margin-bottom: 15px;
      }

      & .started__form {
        max-width: 100%;
        margin-bottom: 15px;
      }

      & .started__note {
        max-width: 100%;
      }
    }
  }

  header {
    &.simplified__header {
      & .outer__header {
        padding-left: 0px;
        padding-right: 0px;

        & .header__logo {
          &>a {
            &>img {
              max-width: 200px;
            }
          }
        }

        & .header__info {
          max-width: 420px;
          text-align: right;

          &>p {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
  }

  .modal__regular.modal__additional--service>.inner__modal--regular {
    padding: 30px 25px;
  }

  .additional__float .upload-content__files {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .upload-content__files-list {
      margin-bottom: 20px;
    }
  }

  .additional__copies--container .copies__container--wrapper>.element__copy .uncheck__file {
    margin-right: 0px;
    margin-bottom: 10px;
  }

  .additional__copies--container .copies__container--wrapper>.element__copy {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .modal__regular.modal__additional--service>.inner__modal--regular {
    max-width: 420px;
  }

  .modal__regular.modal__additional--service>a {
    right: 435px;
  }

  .checkout__progress--section .element__progress {
    min-height: 45px;
    min-width: 70px;
    max-width: 70px;
  }

  .additional__float .float__controls {
    margin-top: 25px;
  }

  .additional__float>.float__inner .float__switcher>p {
    max-width: 90%;
  }

  .additional__float>.float__inner .float__switcher>p img {
    min-width: 15px;
  }

  .additional__float .additional__copies>p {
    max-width: 90%;

    & img {
      min-width: 15px;
    }
  }

  .info__tooltip--block>p {
    font-size: 11px;
  }

  .additional__float>.float__inner>.cost__info>p {
    font-size: 12px;
  }

  .additional__float>.float__inner>.cost__info {
    padding: 10px;
  }

  .additional__float .uncheck__document>p,
  .additional__float>.float__inner>.document__certif--title>p {
    max-width: 90%;
  }

  .additional__float>.float__inner>.document__certif--title p img {
    min-width: 15px;
  }

  .additional__float .uncheck__document>p span img {
    min-width: 15px;
  }

  .additional__guest {
    &>h2 {
      margin-bottom: 15px;
    }
  }

  .additional__guest {
    & .additional__list {
      & .additional__element {
        max-width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &>.additional__left {
          margin-bottom: 15px;
        }

        &>.additional__right {
          margin-left: 0px;
        }
      }
    }
  }

  .brief__wrapper {
    & .improve__field {
      max-width: 100%;

      & .content__files {
        & .upload-content__drag-drop.upload-content__drag-drop__old.upload__block {
          max-width: 100%;
        }
      }
    }
  }

  .guest__payment--method {
    &>h2 {
      margin-bottom: 15px;
    }
  }

  .guest__content--main {
    & .payment__method {
      max-width: 100%;
    }
  }

  .checkout__long {
    & .content__guest {
      &>.guest__content--main {
        & .lang__pair {
          &>.head__pair {
            margin-bottom: 15px;
          }
        }
      }
    }
  }

  .pair__picker {
    & .pair__container {
      &>.head__pair {
        flex-direction: column-reverse;
        position: relative;
        padding-top: 40px;

        &>.pair__remove {
          position: absolute;
          top: 10px;
          right: 20px;
        }

        &>.pair__double {
          & .pair__elem {
            max-width: 100%;
          }
        }
      }
    }
  }

  .checkout__long {
    & .content__guest {
      & .guest__content--main {
        & .signed__form {
          & .element__signed--controls {
            max-width: 100%;
          }
        }

        & .sign__form {
          max-width: 100%;

          & .field__submit {
            max-width: 100%;
          }

          & .button__guest {
            max-width: 100%;
          }
        }
      }

      &>.guest__content--side {
        min-width: 0px;

        & .guest__security {
          padding: 20px 15px;
        }

        &>.guest__summary {
          padding: 20px 15px;
        }
      }

      &>.guest__content--main {
        padding: 25px 15px;

        & .guest__checkout {
          & .guest__private {
            max-width: 100%;
            width: 100%;
          }

          & .guest__business {
            max-width: 100%;
            width: 100%;
          }

          & .guest__switcher {
            max-width: 100%;
            width: 100%;
          }
        }
      }
    }
  }

  .checkout__progress--section {
    grid-template-columns: 1fr;
    grid-row-gap: 5px;

    & .element__progress {
      height: 35px;

      &>span {
        min-width: 24px;
        max-width: 24px;
        min-height: 24px;
        max-height: 24px;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .complex__content {
    & .help__block {
      & .help__links {
        &>.elem__help {
          &>a {
            max-width: 100%;
          }
        }
      }
    }
  }

  .info__tooltip--block {
    min-width: 145px;
    max-width: 145px;
  }
}

@media (max-width: 640px) {
  .simplified__block .checkout__progress--section .element__progress {
    max-width: 100%;
  }

  header {
    &.simplified__header {
      & .outer__header {
        & .header__logo {
          &>a {
            &>img {
              max-width: 170px;
            }
          }
        }

        & .header__info {
          margin-left: 10px;
          max-width: 320px;
        }
      }
    }
  }

  .checkout__progress--section {
    padding-top: 55px;
    position: relative;

    & .element__progress {
      min-width: 0px;
      max-width: 100%;
      width: 100%;
    }

    & .element__progress.active__progress {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
    }
  }

  .thank__guest {
    & .thank__final--download {
      &>a {
        font-size: 13px;
        max-width: 320px;
      }
    }

    & .thank__review {
      margin-bottom: 20px;

      &>p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }

    padding-top: 35px;
    padding-bottom: 45px;

    & .thank__controls {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 320px;
      margin-left: auto;
      margin-right: auto;

      &>a {
        font-size: 13px;
        line-height: 18px;

        &>span {
          margin-right: 8px;
        }

        &:nth-child(1) {
          margin-right: 0px;
          margin-bottom: 10px;
        }
      }
    }

    & .thank__faq {
      &>h6 {
        font-size: 20px;
        line-height: 28px;
      }

      &>.thank__faq--elem {
        &>.thank__faq--title {
          padding-left: 15px;
        }

        &>.thank__faq--content {
          padding: 15px;
          padding-top: 0px;

          &>p {
            font-size: 15px;
            line-height: 23px;
          }
        }
      }

      & .thank__faq--head {
        &>p {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }

    & .thank__info {
      &>h2 {
        font-size: 22px;
        line-height: 30px;
      }

      &>p {
        font-size: 16px;
        line-height: 22px;
      }
    }

    & .thank__image {
      &>img {
        max-width: 95px;
      }
    }
  }
}

@media (max-width: 576px) {
  .order__confirm>.order__info>.order__details>.order__email--wrapper>.order__email {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &>p {
      margin-right: 0px;
      margin-bottom: 5px;
    }
  }

  .translation__wrapper--picker .translation__picker--element .picker__choose--grid>.elem__choose--grid {
    padding: 10px;
    padding-bottom: 15px;
  }

  .additional__float .uncheck__document .uncheck__list .elem__uncheck .uncheck__check {
    margin-right: 5px;
  }

  .pair__picker {
    & .pair__container {
      &>.head__pair {
        &>.pair__double {
          flex-direction: column;

          & .pair__spacer {
            margin: 15px 0px;
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  .profile__header {
    &.guest__profile {
      & .user-dropdown__toggle-name {
        display: inline-flex;
      }
    }
  }
}

@media (max-width: 480px) {
  .select__two .select__multi--field .multi__container>.multi__elem {
    &:nth-child(2) {
      display: none;
    }
  }

  .created__other--full {
    padding-right: 20px;

    & .created__other>a {
      right: -24px !important;
    }

    & .created__other {
      padding-right: 0px !important;
    }
  }

  .created__other--full>.removefull__other {
    right: -4px;
  }

  .created__other>a {
    right: -4px;
  }

  .created__other {
    padding-right: 20px;
  }

  .different__combination .different__combo--inputs .elem__different--combo.combo__created {
    padding-right: 20px;

    &>a {
      right: -4px;
    }
  }

  .more__variant {
    margin-top: 15px;
  }

  .modal__regular.modal__pricing>.inner__modal--regular {
    padding: 30px 15px;
  }

  .table__other--controls {
    margin-top: 25px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &>.table__cancel {
      margin-right: 0px;
      min-width: 0px;
      width: 100%;
      max-width: 100%;
    }

    &>.table__create {
      min-width: 0px;
      width: 100%;
      max-width: 100%;
      margin-right: 0px;
      margin-bottom: 10px;
    }
  }

  .order__confirm>.order__info>.order__details>.order__email--wrapper>.order__email>p {
    font-size: 15px;
    line-height: 23px;
  }

  .order__confirm>.order__info>h2 {
    font-size: 19px;
    line-height: 25px;
  }

  header {
    &.simplified__header {
      & .outer__header {
        flex-direction: column;

        & .header__info {
          margin-left: 0px;
          margin-top: 7px;
          text-align: center;
        }
      }
    }
  }

  .modal__regular.modal__additional--service>.inner__modal--regular {
    max-width: 290px;
    padding: 30px 15px;
  }

  .modal__regular.modal__additional--service>a {
    right: 300px;
  }

  .additional__float .float__controls {
    flex-direction: column;
    width: 100%;
    max-width: 100%;
  }

  .additional__float .float__controls>.float__add {
    margin-right: 0px;
    min-width: 0px;
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }

  .additional__float .float__controls>.float__cancel {
    min-width: 0px;
    width: 100%;
    max-width: 100%;
  }

  .additional__guest .additional__list .additional__element>.additional__left {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &>.additional__left--image {
      margin-right: 0px;
      margin-bottom: 10px;
    }
  }

  .additional__guest .additional__list .additional__element>.additional__right {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &>.additional__controls {
      margin-left: 0px;
    }

    &>.price__additional {
      margin-bottom: 10px;
    }
  }

  .additional__complex--controls {
    &>.complex__controls--inner {
      flex-wrap: wrap;
      max-width: 240px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .guest__content--main {
    & .payment__method {
      & .payment__method--grid {
        &>.elem__payment--method {
          padding: 15px 12px;

          &>p {
            &>span {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  .checkout__long {
    & .content__guest {
      &>.guest__content--main {
        & .guest__checkout {
          & .guest__switcher {
            & ul {
              flex-direction: column;
              justify-content: center;
              align-items: center;

              &>li {
                margin-right: 0px;
                margin-bottom: 3px;

                &:last-child {
                  margin-bottom: 0px;
                }
              }
            }
          }
        }
      }
    }
  }

  .complex__content {
    & .help__block {
      & .help__links {
        &>.elem__help {
          & .help__info {
            &>h6 {
              font-size: 15px;
              line-height: 19px;
            }
          }

          &>a {
            padding-right: 15px;
            flex-direction: column;

            &>.help__svg {
              margin-left: 0px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 360px) {
  .modal__regular.modal__additional--service>.inner__modal--regular {
    max-width: 260px;
    padding: 30px 15px;
  }

  .modal__regular.modal__additional--service>a {
    right: 270px;
  }
}