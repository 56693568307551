$white: #fff;

$base-color: #3b3b3b;
$gray-text: #9d9d9d;
$dark-gray: #444;
$light-gray: #e5e5e5;
$green-text: #0e7d7d;
$light-green: #00d06c;
$green-border: #009688;
$dark-green: #0a6161;
$red-text: #ff5b5b;
$text-blue: #3f88c5;
$gray-bg: #f7f7f7;
$deep-sea-green: #085a5a;
$pine-green: #158b8b;
$rose-white: #eaeffb;
$picton-blue: #5ca4da;
$steel-blue: #457ba3;
$scandal-color: #b7d8d8;
$fountain-blue: #68b9b9;
$glacier: #87bebe;
