.summary__right {
  .upload-manual {
    font-size: 12px;
    font-weight: 400;
    position: relative;
    width: auto;
    text-align: left;
    margin-left: auto;
    // margin-right: 10px;
    @include transition(all, 600ms);
    // transition: all 0.4s ease;
    .btn-preview-toggle {
      width: 51px;
      height: 19px;
      background: $picton-blue;
      border-radius: 10px;
      padding: 0;
      margin: 0 0 0 7px;
      position: relative;

      &:hover {
        background: $steel-blue;
      }

      &__text {
        font-weight: 600;
        font-size: 12px;
        line-height: 19px;
        color: white;
        text-align: center;
        display: block;
        position: relative;
        padding-right: 17px;
        transition: all 0.4s ease;

        &::before {
          content: '';
          width: 17px;
          height: 17px;
          position: absolute;
          transition: all 0.4s ease;
          right: 1px;
          top: 1px;
          border-radius: 50%;
          background: rgba(0, 0, 0, 0.25)
            url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjEzIiBmaWxsPSJub25lIj48cGF0aCBkPSJNNS42NjUgMTAuODQ3bC0uMTU1LjYzMy0xLjExMi40MTljLS4yNzcuMDk2LS41OTguMTQ0LS45NjUuMTQ0LS41NjMgMC0xLS4xMzgtMS4zMTItLjQxMmExLjMzIDEuMzMgMCAwIDEtLjQ2OC0xLjA0NiAzLjcgMy43IDAgMCAxIC4wMzUtLjUwM2MuMDI0LS4xNzIuMDYyLS4zNjUuMTEzLS41ODFsLjU4MS0yLjA1Ni4xMzEtLjU2Yy4wMzYtLjE3NS4wNTMtLjMzNy4wNTMtLjQ4MiAwLS4yNjMtLjA1NC0uNDQ2LS4xNjMtLjU0OVMyLjA4OCA1LjcgMS43ODEgNS43YTEuNjMgMS42MyAwIDAgMC0uNDYyLjA3MWwtLjQwNi4xMzQuMTU1LS42MzQgMS4wOTItLjM5OGMuMzQ4LS4xMS42NzctLjE2Ni45ODgtLjE2Ni41NTkgMCAuOTkuMTM1IDEuMjkzLjQwNXMuNDU0LjYyMS40NTQgMS4wNTJjMCAuMDg5LS4wMS4yNDctLjAzMS40NzJzLS4wNi40MzItLjExNi42MmwtLjU3OCAyLjA0N2MtLjA0Ny4xNjQtLjA5LjM1Mi0uMTI3LjU2NHMtLjA1Ny4zNzEtLjA1Ny40NzhjMCAuMjcyLjA2MS40NTcuMTgyLjU1NnMuMzMzLjE0OC42MzMuMTQ4Yy4xNDEgMCAuMzAxLS4wMjUuNDc4LS4wNzRzLjMwNy0uMDkyLjM4Ny0uMTN6bS4xNDctOC41OTZjMCAuMzU3LS4xMzQuNjYxLS40MDUuOTEycy0uNTk0LjM3Ny0uOTc0LjM3N2ExLjM5IDEuMzkgMCAwIDEtLjk3OS0uMzc3Yy0uMjcyLS4yNTEtLjQwOC0uNTU1LS40MDgtLjkxMnMuMTM2LS42NjEuNDA4LS45MTUuNTk4LS4zOC45NzktLjM4LjcwNS4xMjcuOTc0LjM4YTEuMjEgMS4yMSAwIDAgMSAuNDA1LjkxNXoiIGZpbGw9IiNmZmYiLz48L3N2Zz4=')
            center no-repeat;
        }
      }

      &__text:hover::before {
        background-color: $steel-blue;
      }
    }
    svg {
      margin: 0 0 -4px 0;
      cursor: pointer;
    }
  }
  .upload-manual-review {
    background: $white;
    box-shadow: 3px 5px 30px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 20px 20px 16px;
    width: 238px;
    position: absolute;
    right: 7px;
    top: 22px;
    z-index: 9;
    display: none;
    &-visible {
      display: block;
    }
    b {
      font-size: 14px;
      font-weight: 600;
      color: $base-color;
      margin-bottom: 10px;
      display: block;
    }
    p {
      font-size: 12px;
      color: $base-color;
      padding: 0;
      width: auto !important;
      white-space: inherit;
    }
  }
}
