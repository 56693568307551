@media (max-width: 1200px) {
  .upload-content__wrapper {
    width: calc(100% - 352px);
  }
  .summary {
    width: 337px;
    ul {
      li {
        padding: 5px 0;
        span {
          &:nth-of-type(1) {
            width: 30%;
          }
          &:nth-of-type(2) {
            width: 23%;
          }
          &:nth-of-type(3) {
            width: 31%;
          }
          &:nth-of-type(4) {
            width: 12%;
          }
        }
      }
    }
  }
} /* 1200px */
@media (max-width: 991px) {
  .upload-content,
  .content-files {
    .container {
      flex-direction: column;
    }
    &__wrapper {
      width: 100%;
      margin: 0 0 15px;
    }

    .form-group {
      &:hover {
        .uploading,
        .in-quote,
        .upload-edit {
          opacity: 1;
          visibility: visible;
        }

        // .upload-manual {
        //     margin-right: 10px;
        // }
      }
    }
  }
  .summary {
    width: 100%;
    ul {
      li {
        padding: 5px 0;
        span {
          &:nth-of-type(1) {
          }
          &:nth-of-type(2) {
          }
          &:nth-of-type(3) {
          }
          &:nth-of-type(4) {
          }
        }
      }
    }
  }
} /* 991px */
@media (max-width: 767px) {
  .upload-content,
  .content-files {
    &__files {
      flex-direction: column;
    }
    &__files-list {
      width: 100%;
      padding-right: 20px;
      .form-group {
        span {
          width: auto;
        }
      }
    }
    &__files-upload__button {
      margin: 0;
      padding: 0 0 20px;
      border: 0;
      width: 100%;
      button {
        margin: 0;
      }
    }

    &__files-list__options {
      visibility: visible;
      opacity: 1;
      width: auto !important;
      position: relative;
      top: auto;
      right: auto;
      margin-top: 0;
      display: flex;
      padding-right: 10px;

      .form-group_hint & {
        width: auto !important;
        visibility: visible;
        opacity: 1;
        padding-right: 0;
      }
    }

    .form-group {
      &__buttons {
        width: 33px !important;
        margin-left: 10px;
      }

      &:hover {
        .uploading,
        .in-quote,
        .upload-edit {
          opacity: 1;
          visibility: visible;
          display: block;
        }
      }

      &_hint .upload-content__files-list__options {
        right: 0;
      }
    }
  }
} /* 767px */
@media (max-width: 550px) {
  .upload-content {
    &__wrapper {
      padding: 15px 15px 30px 15px;
    }
    &__buttons {
      margin: 0;
      padding: 0 15px;
      border: 0;
      flex-direction: column;
      label,
      button {
        &.button {
          align-self: center;
          margin: 5px 0;
        }
      }
    }
    &__text-edit__buttons {
      margin-bottom: 0;
    }
    &__drag-drop {
      p {
        display: none;
      }
    }
    &__files {
      margin-bottom: 0;
    }

    &__files-list {
      padding: 15px;
      .form-group {
        p {
          padding: 0 10px;
        }
      }
    }

    .form-group {
      flex-wrap: wrap;

      .uploading-loadbar {
        left: 2px;
        right: 0;
        border-radius: 0 0 7px 7px;
      }

      &:hover .upload-manual {
        margin-right: 0;
      }

      .upload-manual-review {
        left: auto;
        right: 7px;
      }
    }
  }
} /* 550px */
@media (max-width: 350px) {
  .summary {
    ul {
      li {
        span {
          &:nth-of-type(1) {
            width: 25%;
          }
        }
      }
    }
  }
}
