.catalog__right.roles__right {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.catalog__right.roles__right .new__button {
  margin-left: 15px;
}
.catalog__right.roles__right .new__button > a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  min-height: 40px;
  box-shadow: inset 0 0 0 1px #0e7d7d, 0 4px 5px rgb(0 0 0 / 5%);
  background-color: #fff;
  color: #0e7d7d;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  transition: 0.4s ease all;
}
.catalog__right.roles__right .new__button > a:hover {
  color: #0a6161;
  box-shadow: inset 0 0 0 2px #0e7d7d;
}
.catalog__right.roles__right .new__button > a:active {
  color: #0a6161;
  box-shadow: inset 0 0 0 3px rgb(14 125 125 / 30%);
}
.catalog__right.roles__right .new__button > a span {
  display: inline-flex;
  margin-right: 15px;
}
.catalog__right.roles__right .export__button > a span {
  display: inline-flex;
  margin-right: 15px;
}

.catalog__right.roles__right .export__button > a:hover {
  color: #0a6161;
  box-shadow: inset 0 0 0 2px #0e7d7d;
}
.catalog__right.roles__right .export__button > a:active {
  color: #0a6161;
  box-shadow: inset 0 0 0 3px rgb(14 125 125 / 30%);
}
.catalog__right.roles__right .export__button > a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  min-height: 40px;
  box-shadow: inset 0 0 0 1px #0e7d7d, 0 4px 5px rgb(0 0 0 / 5%);
  background-color: #fff;
  color: #0e7d7d;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  transition: 0.4s ease all;
}
.catalog__right.roles__right .export__button {
  margin-left: 15px;
}

.permissions__table {
  width: 100%;
  padding-bottom: 120px;
}
.inner__permissions--table {
  width: 100%;
}
.head__permissions--table {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #e3e3e3;
}
.row__permissions--table {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.head__permissions--table {
  padding-right: 50px;
  padding-left: 20px;
  padding-bottom: 15px;
}
.head__permissions--table .el__permissions--table.center__permissions {
  justify-content: center;
}
.head__permissions--table .el__permissions--table {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.head__permissions--table .el__permissions--table p span {
  display: inline-flex;
  margin-left: 5px;
}
.head__permissions--table .el__permissions--table p {
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  color: #2d2d2d;
  font-size: 14px;
  font-weight: 600;
}
.head__permissions--table .el__permissions--table.center__permissions p {
  width: 100%;
  display: flex;
  justify-content: center;
}
.permissions__table .head__permissions--table .el__permissions--table:nth-child(1) {
  min-width: 115px;
  padding-left: 40px;
  max-width: 115px;
}
.permissions__table .head__permissions--table .el__permissions--table:nth-child(2) {
  min-width: 115px;
  max-width: 115px;
}
.permissions__table .head__permissions--table .el__permissions--table:nth-child(3) {
  min-width: 145px;
  max-width: 145px;
}
.permissions__table .head__permissions--table .el__permissions--table:nth-child(4) {
  min-width: 520px;
  max-width: 520px;
}
.permissions__table .head__permissions--table .el__permissions--table:nth-child(5) {
  min-width: 85px;
  max-width: 85px;
}

.row__permissions--table {
  padding-right: 50px;
  padding-left: 20px;
}
.row__permissions--table .el__permissions--table > p {
  color: #2d2d2d;
  font-size: 13px;
  font-weight: 400;
  margin: 0px;
}
.row__permissions--table .el__permissions--table:nth-child(1) {
  min-width: 115px;
  max-width: 115px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  position: relative;
  top: -2px;
}
.row__permissions--table .el__permissions--table:nth-child(2) {
  min-width: 115px;
  max-width: 115px;
  position: relative;
  top: -2px;
}
.row__permissions--table .el__permissions--table:nth-child(3) {
  min-width: 145px;
  max-width: 145px;
  position: relative;
  top: -2px;
}
.row__permissions--table .el__permissions--table:nth-child(4) {
  min-width: 520px;
  max-width: 520px;
  position: relative;
  top: -2px;
}
.row__permissions--table .el__permissions--table:nth-child(5) {
  min-width: 85px;
  max-width: 85px;
  position: relative;
  top: 0px;
}

.row__permissions--table .el__permissions--table:nth-child(1) .check__input {
  margin-right: 22px;
  margin-left: -20px;
}
.el__permissions--table .route__wrapper:last-child {
  margin-bottom: 0px;
}
.el__permissions--table .route__wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.el__permissions--table .route__wrapper .route__head {
  margin-right: 20px;
}
.el__permissions--table .route__wrapper .route__main > span {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 10px;
  background: rgba(63, 136, 197, 0.15);
  font-family: 'Arvo', sans-serif;
  font-family: 400;
  color: rgba(63, 136, 197, 1);
  font-size: 13px;
  line-height: 16px;
  word-break: break-all;
}
.el__permissions--table .route__wrapper .route__head > span {
  display: inline-flex;
  padding: 5px 10px;
  background: #3f88c5;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-weight: 600;
  min-width: 45px;
  line-height: 16px;
}
.el__permissions--table.center__permissions p {
  text-align: center;
}
.el__permissions--table .action__permissions--buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.el__permissions--table .action__permissions--buttons .edit__button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.el__permissions--table .action__permissions--buttons .edit__button > a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  transition: 0.4s ease all;
  max-height: 30px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
}
.el__permissions--table .action__permissions--buttons .edit__button > a:hover,
.el__permissions--table .action__permissions--buttons .remove__button > a:hover {
  border-color: #0e7d7d;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 10%);
}
.el__permissions--table .action__permissions--buttons .remove__button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.el__permissions--table .action__permissions--buttons .remove__button > a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  transition: 0.4s ease all;
  max-height: 30px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
}
.row__permissions--table .el__permissions--table .check__input {
  opacity: 0;
  position: none;
}
.row__permissions--table {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e3e3e3;
}
.modal__wrapper.modal__role > .role__inner {
  width: 100%;
  max-width: 660px;
  right: -660px;
  height: calc(100% - 60px);
  margin-left: auto;
  margin-top: 60px;
  background: #ffffff;
  box-shadow: 5px 10px 30px rgb(0 0 0 / 10%);
  border-radius: 5px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  position: relative;
  overflow-y: auto;
  padding: 50px 45px;
  transition: 0.4s ease all;
}
.modal__wrapper.modal__role.permissions__modal > a {
  opacity: 0;
}
.modal__wrapper.modal__role.permissions__modal > .role__inner {
  right: -660px;
}
.modal__wrapper.modal__role .form-group.required__form > span {
  color: #cc2936;
  font-size: 14px;
  position: absolute;
  left: -15px;
}
.modal__wrapper.modal__role .form-group {
  margin-bottom: 15px;
}
.modal__wrapper.modal__role .form-group:last-child {
  margin-bottom: 0px;
}
.modal__wrapper.modal__role .double__created {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal__wrapper.modal__role .form-group.required__form .input-animation {
  width: 100%;
}
.modal__wrapper.modal__role .form-group.required__form {
  position: relative;
  align-items: center;
  display: flex;
  width: 100%;
}
.modal__wrapper.modal__role .role__inner > h2 {
  margin-top: 0px;
  margin-bottom: 45px;
  color: rgba(14, 125, 125, 1);
  font-size: 25px;
  line-height: 34px;
  font-weight: 700;
}
.modal__wrapper.modal__role > a:hover {
  background: #5c5a64;
}
.modal__wrapper.modal__role > a:active {
  border-color: #fff;
}
.modal__wrapper.modal__role > a {
  position: absolute;
  top: 70px;
  right: 675px;
  transition: 0.4s ease all;
  opacity: 1;
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
}
.modal__wrapper.modal__role {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 15;
  background-color: rgba(0, 0, 0, 0.7);
}
.modal__role .reset__role > a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 90px;
  min-height: 35px;
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  color: rgba(14, 125, 125, 1);
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  transition: 0.4s ease all;
}
.modal__role .reset__role > a:hover {
  opacity: 0.7;
}
.modal__role .reset__role {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.modal__wrapper.modal__role .double__created {
  display: grid;
  margin-bottom: 20px;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
}
.double__created .task__el .datepicker:after {
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  background-color: #ffffff;
  background-size: 22px;
  box-shadow: 0px 4px 5px rgb(0 0 0 / 5%);
  border-radius: 3px;
  right: 0px;
}
.double__created .task__el .datepicker .input {
  background-color: #f7f7f7;
  border: 0px;
  height: 40px;
  font-size: 12px;
}
.role__controls {
  display: flex;
  justify-content: flex-start;
  margin-top: 50px;
}
.role__controls > a {
  min-width: 125px;
}
.role__controls > input {
  max-width: 115px;
  margin-right: 15px;
}
.double__created .task__el > p {
  margin: 0px;
  margin-bottom: 5px;
  color: rgba(45, 45, 45, 1);
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
}
.method__notif {
  margin-top: 10px;
}
.method__notif p span {
  display: inline-flex;
  margin-right: 5px;
}
.path__textarea > textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #9d9d9d;
}
.path__textarea > textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #9d9d9d;
}
.path__textarea > textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #9d9d9d;
}
.path__textarea > textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #9d9d9d;
}
.path__textarea > textarea {
  width: 100%;
  height: 65px;
  resize: none;
  border-radius: 3px;
  color: #444444;
  border: 1px solid transparent;
  padding: 10px 15px;
  font-size: 14px;
  background-color: #ffffff;
  border-color: #dadada;
}
.method__notif p {
  color: rgba(158, 158, 158, 1);
  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.roles__table .roles__table--head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 50px;
  padding-left: 20px;
  padding-bottom: 15px;
  width: 100%;
  border-bottom: 1px solid #e3e3e3;
}
.roles__table .roles__table--row {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding-right: 50px;
  padding-left: 20px;
  padding-right: 50px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e3e3e3;
}
.roles__table .roles__table--head > .elem__roles--table p {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  color: #2d2d2d;
  font-size: 14px;
  font-weight: 600;
}
.roles__table .roles__table--head > .elem__roles--table p span {
  display: inline-flex;
  margin-left: 5px;
}
.roles__table .roles__table--head > .elem__roles--table:nth-child(1) {
  min-width: 115px;
  padding-left: 40px;
  max-width: 115px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.roles__table--row > .elem__roles--table p {
  margin: 0px;
  color: #2d2d2d;
  font-size: 13px;
  font-weight: 400;
}
.roles__table--row > .elem__roles--table .action__roles--buttons .edit__button > a:hover,
.roles__table--row > .elem__roles--table .action__roles--buttons .remove__button > a:hover {
  border-color: #0e7d7d;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 10%);
}
.roles__table--row > .elem__roles--table .action__roles--buttons .remove__button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.roles__table--row > .elem__roles--table .action__roles--buttons .remove__button > a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  transition: 0.4s ease all;
  max-height: 30px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
}
.roles__table--row > .elem__roles--table .action__roles--buttons .edit__button > a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  transition: 0.4s ease all;
  max-height: 30px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
}
.roles__table--row > .elem__roles--table .action__roles--buttons .edit__button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.roles__table--row > .elem__roles--table .action__roles--buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.roles__table--row > .elem__roles--table:nth-child(1) {
  min-width: 115px;
  padding-left: 20px;
  max-width: 115px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  top: -2px;
}
.roles__table--row > .elem__roles--table .check__input {
  margin-right: 22px;
  margin-left: -20px;
  opacity: 0;
  pointer-events: none;
}
.roles__table .roles__table--head > .elem__roles--table:nth-child(2),
.roles__table--row > .elem__roles--table:nth-child(2) {
  min-width: 110px;
  max-width: 110px;
}
.roles__table--row > .elem__roles--table:nth-child(2) {
  position: relative;
  top: -2px;
}
.roles__table .roles__table--head > .elem__roles--table:nth-child(3),
.roles__table--row > .elem__roles--table:nth-child(3) {
  min-width: 125px;
  max-width: 125px;
}
.roles__table--row > .elem__roles--table:nth-child(3) {
  position: relative;
  top: -2px;
}
.roles__table .roles__table--head > .elem__roles--table:nth-child(4),
.roles__table--row > .elem__roles--table:nth-child(4) {
  min-width: 620px;
  max-width: 620px;
}
.roles__table--row > .elem__roles--table:nth-child(4) {
  position: relative;
  top: -3px;
}
.roles__table .roles__table--head > .elem__roles--table:nth-child(5),
.roles__table--row > .elem__roles--table:nth-child(5) {
  min-width: 85px;
  max-width: 85px;
}
.roles__table .elem__roles--table .permissions__list .elem__permission p {
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  padding: 5px 10px;
  background: #18a6a6;
  border-radius: 4px;
}
.roles__table .elem__roles--table .permissions__list .elem__permission {
  padding-right: 5px;
  padding-bottom: 5px;
}
.roles__table .elem__roles--table .permissions__list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: -5px;
}
.permissions__field .permissions__field--inner .el__field--permissions p {
  margin: 0px;
  margin-right: 5px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: #fff;
}
.permissions__field .permissions__field--inner .el__field--permissions {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  background: #18a6a6;
  border-radius: 4px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.permissions__field {
  margin-bottom: 15px;
}
.permissions__field .new__permissions {
  margin-bottom: 5px;
  margin-right: 5px;
}
.permissions__field .new__permissions > input {
  color: #2d2d2d;
  font-size: 14px;
  font-weight: 400;
  height: 26px;
}
.permissions__field .permissions__field--inner {
  padding: 5px;
  border: 1px solid #c5c5c5;
  border-radius: 3px;
  display: flex;
  flex-wrap: wrap;
}
.permissions__field {
  position: relative;
}
.permissions__field .permissions__dropdown ul::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

/* Track */
.permissions__field .permissions__dropdown ul::-webkit-scrollbar-track {
  background: #e9e9e9;
}

/* Handle */
.permissions__field .permissions__dropdown ul::-webkit-scrollbar-thumb {
  background: #aeaeae;
  border-radius: 5px;
}
.permissions__field .permissions__dropdown ul li {
  display: flex;
  width: 100%;
}
.permissions__field .permissions__dropdown ul li a:hover {
  background: #ebf1f1;
}
.permissions__field .permissions__dropdown ul li a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 15px;
  color: rgba(14, 125, 125, 1);
  font-size: 14px;
  line-height: 21px;
  width: 100%;
  font-weight: 600;
  transition: 0.4s ease all;
  border-radius: 4px;
}
.permissions__field .permissions__dropdown ul {
  padding: 0px;
  list-style-type: none;
  margin: 0px;
  max-height: 310px;
  overflow-y: auto;
  padding-right: 10px;
}
.permissions__field .permissions__dropdown {
  position: absolute;
  left: 0px;
  top: 75px;
  width: 100%;
  display: none;
  background: #ffffff;
  box-shadow: 5px 10px 40px rgb(0 0 0 / 5%);
  border-radius: 3px;
  z-index: 2;
  padding: 20px;
  padding-right: 10px;
}
.example__permissions {
  display: none !important;
}
.permissions__catalog {
  margin-bottom: 10px;
}
@media (max-width: 1440px) {
  .permissions__table,
  .roles__table {
    overflow-x: auto;
  }
  .inner__permissions--table,
  .roles__table--inner {
    min-width: 1120px;
  }
}
@media (max-width: 1200px) {
  .permissions__catalog {
    justify-content: flex-end;
  }
  .permissions__catalog .catalog__header {
    overflow-x: visible;
  }
  .catalog__right.roles__right {
    width: auto;
  }
}
@media (max-width: 767px) {
  .modal__wrapper.modal__role > .role__inner {
    max-width: 420px;
  }
  .modal__wrapper.modal__role .double__created {
    grid-template-columns: 1fr;
    grid-row-gap: 15px;
  }
  .modal__wrapper.modal__role .role__inner > h2 {
    margin-bottom: 25px;
  }
  .modal__wrapper.modal__role > a {
    right: 420px;
  }
}
@media (max-width: 480px) {
  .role__controls {
    margin-top: 25px;
    flex-direction: column;
  }
  .role__controls > input {
    max-width: 100%;
    width: 100%;
    margin-bottom: 10px;
  }
  .modal__wrapper.modal__role .form-group.required__form > span {
    left: -10px;
  }
  .modal__wrapper.modal__role > a {
    right: 300px;
  }
  .modal__wrapper.modal__role > .role__inner {
    max-width: 290px;
    padding: 30px 15px;
  }
}
@media (max-width: 360px) {
  .catalog__right.roles__right .export__button > a {
    min-width: 110px;
  }
  .catalog__right.roles__right .export__button {
    margin-left: 10px;
  }
  .catalog__right.roles__right .new__button {
    margin-left: 10px;
  }
  .modal__wrapper.modal__role > a {
    right: 260px;
  }
  .modal__wrapper.modal__role > .role__inner {
    max-width: 260px;
  }
}
