@mixin truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin transition($transition-value, $transition-time) {
  -webkit-transition: $transition-value $transition-time ease-in-out;
  -moz-transition: $transition-value $transition-time ease-in-out;
  -o-transition: $transition-value $transition-time ease-in-out;
  transition: $transition-value $transition-time ease-in-out;
}
